import { createSlice } from "@reduxjs/toolkit";

const adminMPSSlice = createSlice({
  name: "adminMPS",
  initialState: {
    adminMPS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminMPS
    getAdminMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminMPSSuccess: (state, action) => {
      state.isFetching = false;
      state.adminMPS = action.payload;
    },
    getAdminMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminMPS
    deleteAdminMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminMPSSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminMPS
    updateAdminMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminMPSSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminMPS
    addAdmnMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdmnMPSSuccess: (state, action) => {
      state.isFetching = false;
      // state.adminMPS.push(action.payload);
    },
    addAdmnMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminMPSStart,
  getAdminMPSSuccess,
  getAdminMPSFailure,
  deleteAdminMPSStart,
  deleteAdminMPSSuccess,
  deleteAdminMPSFailure,
  updateAdminMPSStart,
  updateAdminMPSSuccess,
  updateAdminMPSFailure,
  addAdmnMPSStart,
  addAdmnMPSSuccess,
  addAdmnMPSFailure,
} = adminMPSSlice.actions;
export default adminMPSSlice.reducer;
