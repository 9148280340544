import { createSlice } from "@reduxjs/toolkit";

const calenderSlice = createSlice({
  name: "calender",
  initialState: {
    calender: [],
    isFetching: false,
    isSuccess: false,
    error: false,
  },
  reducers: {
    //GET Calender Data
    getCalenderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getCalenderSuccess: (state, action) => {
      state.isFetching = false;
      state.calender = action.payload;
    },
    getCalenderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE Calender Data
    deleteCalenderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteCalenderSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteCalenderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE Calender Data
    updateCalenderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateCalenderSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateCalenderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD Calender Data
    addCalenderStart: (state) => {
      state.isFetching = true;
      state.isSuccess = true;
      state.error = false;
    },
    addCalenderSuccess: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      // state.calender.push(action.payload);
    },
    addCalenderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getCalenderStart,
  getCalenderSuccess,
  getCalenderFailure,
  deleteCalenderStart,
  deleteCalenderSuccess,
  deleteCalenderFailure,
  updateCalenderStart,
  updateCalenderSuccess,
  updateCalenderFailure,
  addCalenderStart,
  addCalenderSuccess,
  addCalenderFailure,
} = calenderSlice.actions;
export default calenderSlice.reducer;
