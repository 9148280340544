import React from "react";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { AdminDDSTabsComp } from "../../components/TabsComp/AdminDDSTabsComp";
import "../../components/Drawers/Drawer.css";

export const AdminDDS = () => {
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            DDS DMS Health Check
          </h2>
          <BreadCrumbs
            first="DailyDirectionSettings"
            second="AdministrationDailyDirection"
          />
        </div>
      </div>
      <div>
        <AdminDDSTabsComp />
      </div>
    </div>
  );
};
