import {
    CREATE_CIL_USER,
    SELECT_CIL_USER,
    userRequest,
  } from "../../apiRoutes/apiRoutes";
import { addUserCILStart,addUserCILFailure,
  addUserCILSuccess,getUserCILStart,getUserCILFailure,getUserCILSuccess } from "../reducers/userCILReducer";
  import { toast } from "react-toastify";
  
  export const createUserDataCIL = async (dispatch, inputData) => {
    dispatch(addUserCILStart());
    try {
      const result = await userRequest.post(`${CREATE_CIL_USER}`, inputData);
      console.log("result from createQuestionCIL", result);
      // toast.success("Feedback Added Successfully");
  
      dispatch(addUserCILSuccess(result));
      toast.success("Inserted Successfully");
      return result;
    } catch (err) {
      console.log("error from catch", err);
      toast.error("Server Error");
  
      dispatch(addUserCILFailure());
    }
  };
  export const selectUserDataCIL = async (dispatch) => {
    dispatch(getUserCILStart());
    try {
      const result = await userRequest.get(`${SELECT_CIL_USER}`);
      // console.log("result from getQuestionDH", result);
      // toast.success("Successfully")
      dispatch(getUserCILSuccess(result.data.payload));
    } catch (err) {
      console.log("error from catch", err);
      dispatch(getUserCILFailure());
    }
  };
  