import { createSlice } from "@reduxjs/toolkit";

const userDDSSlice = createSlice({
  name: "userDDS",
  initialState: {
    userDDS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET UserDDS
    getUserDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserDDSSuccess: (state, action) => {
      state.isFetching = false;
      state.userDDS = action.payload;
    },
    getUserDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserDDS
    deleteUserDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserDDSSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserDDS
    updateUserDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserDDSSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserDDS
    addUserDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserDDSSuccess: (state, action) => {
      state.isFetching = false;
      // state.userDDS.push(action.payload);
    },
    addUserDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserDDSStart,
  getUserDDSSuccess,
  getUserDDSFailure,
  deleteUserDDSStart,
  deleteUserDDSSuccess,
  deleteUserDDSFailure,
  updateUserDDSStart,
  updateUserDDSSuccess,
  updateUserDDSFailure,
  addUserDDSStart,
  addUserDDSSuccess,
  addUserDDSFailure,
} = userDDSSlice.actions;
export default userDDSSlice.reducer;
