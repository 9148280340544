import { createSlice } from "@reduxjs/toolkit";

const AdminQuestionCILSlice = createSlice({
  name: "AdminQuestionCIL",
  initialState: {
    AdminQuestionCIL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET QuestionCIL
    getQuestionCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getQuestionCILSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminQuestionCIL = action.payload;
    },
    getQuestionCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE QuestionCIL
    
  },
});

export const {
  getQuestionCILStart,
  getQuestionCILSuccess,
  getQuestionCILFailure,
 
} = AdminQuestionCILSlice.actions;
export default AdminQuestionCILSlice.reducer;
