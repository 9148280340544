import React from "react";
import "./Drawer.css";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { TabsComp } from "../../components/TabsComp/TabsComp";

export const AdminDefectHandling = () => {
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            DH DMS Health Check
          </h2>
          <BreadCrumbs
            first="DefectHandling"
            second="AdministrationDefecthandling"
          />
        </div>
      </div>
      <div>
        <TabsComp />
      </div>
    </div>
  );
};
