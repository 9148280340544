import React from "react";
import "../../components/Drawers/Drawer.css";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { AdminCLTabsComp } from "../../components/TabsComp/AdminCLTabsComp";

export const AdminCL = () => {
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            CL DMS Health Check
          </h2>
          <BreadCrumbs first="CenterLine" second="AdministrationCL" />
        </div>
      </div>
      <div>
        <AdminCLTabsComp />
      </div>
    </div>
  );
};
