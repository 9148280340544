import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MaterialTable from "material-table";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { domain, userRequest } from "../../apiRoutes/apiRoutes";
import {
  getAreaAndlineData,
  getOwners,
} from "../../store/redux-apis/GlobalApi";
import {
  createEmailLL,
  deleteEmailLL,
  getLLEmail,
  updateEmailLL,
} from "../../store/redux-apis/EmailApi";
import { toast } from "react-toastify";
import { Input, MenuItem, Select } from "@mui/material";

const headerStyleObj = {
  backgroundColor: "#112564",
  color: "#ffffff",
  borderBottom: "#E4E6EF",
  borderBottomWidth: "1px",
  borderBottomStyle: "dashed",
  fontWeight: "600",
  fontSize: "12px",
};

const searchFieldStyleObj = {
  backgroundColor: "#EAEDF7",
  borderBottom: "0px",
  borderRadius: "10px",
  padding: "4px",
};

const EmailConfig = () => {
  const getAreaOrtLineData = useSelector(
    (state) => state.globalState.getAreaData
  );
  console.log("getAreaOrLineData", getAreaOrtLineData);

  const getOwnersData = useSelector((state) => state.globalState.getOwnersData);
  console.log("getOwnersData===>", getOwnersData);

  const columns = [
    {
      title: "Line Name",
      field: "lineName",

      cellStyle: {
        //   width: "50%",
        //   maxWidth: "50%",
        fontSize: "12px",
      },
      headerStyle: {
        //   width: "20%",
        //   maxWidth: "20%",
        fontSize: "12px",
      },
    },

    {
      title: "User Email",
      field: "userEmail",

      cellStyle: {
        // width: "20%",
        // maxWidth: "20%",
        fontSize: "12px",
      },
      headerStyle: {
        // width: "20%",
        // maxWidth: "20%",
        fontSize: "12px",
      },
    },
  ];

  const [show, setShow] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isDisabled, setIsDisabled] = useState();
  const [areaData, setAreaData] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [tableData, setTableData] = useState([]);

  console.log("areaData >>>", areaData);
  console.log("userEmail>>>", userEmail);
  console.log("tableData,,,", tableData);

  const handleLine = (e) => {
    setAreaData(e.target.value);
  };
  const handleUser = (e) => {
    setUserEmail(e.target.value);
  };

  const getLineData = async () => {
    let result = await userRequest.get(
      `${domain}lineLeaderEmail/selectLineLeaderEmail`
    );
    console.log("result >>>>", result);
    setTableData(result.data.payload);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getLineData();
    getLLEmail(dispatch);
    getAreaAndlineData(dispatch);
    getOwners(dispatch);
  }, [dispatch, isRefresh]);

  const addData = (event) => {
    event.preventDefault();
  };

  const inputData = {
    userEmail: userEmail,
    lineName: areaData,
  };
  console.log("inputData===>", inputData);

  // const response =  getLLEmail(dispatch , inputData)
  //     console.log("response==>",response);
  //     // setTableData(res.data.payload)

  const handleAddQuestion = async (e) => {
    if (!userEmail || !areaData) {
      toast.warn("Please Fill all the fields");
    } else {
      setIsDisabled(true);
      let res = await createEmailLL(dispatch, inputData);
      console.log("response from api ====>", res);
      if (res.data.success) {
        setIsDisabled(false);
        setIsRefresh((preValue) => !preValue);
        setAreaData("");
        setUserEmail("");
      }
    }
  };

  return (
    <div>
      <div>
        <button
          type="button"
          className="btn-icon-text my-2 me-2 btn btn-white bg-success text-white"
          onClick={handleShow}
        >
          <AddToPhotosOutlinedIcon
            style={{ fontSize: "16px" }}
            className="me-2"
          />
          Add Email
        </button>
      </div>
      <div
        className="material-table-parent-div d-flex w-100  my-3 "
        style={{ borderRadius: "12px" }}
      >
        <MaterialTable
          style={{
            zIndex: 0,
            borderRadius: "12px",
          }}
          columns={columns}
          data={tableData}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  // const dataUpdate = [...tableData];
                  const dataUpdate = [...tableData];
                  const index = oldData.id;
                  dataUpdate[index] = newData;
                  updateEmailLL(dataUpdate[index], dispatch);
                  // getOrder(dispatch);
                  getLLEmail(dispatch);
                  setIsRefresh((preValue) => !preValue);

                  setShow(false);
                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const index = oldData.id;
                  deleteEmailLL(index, dispatch);
                  setShow(false);
                  getLLEmail(dispatch);
                  setIsRefresh((preValue) => !preValue);
                  resolve();
                }, 1000);
              }),
          }}
          options={{
            // actionsColumnIndex: -1,
            exportButton: true,
            search: false,
            showTitle: false,
            toolbar: false,
            filtering: true,
            pageSizeOptions: [
              5,
              10,
              20,
              {
                value: tableData ? tableData.length : [],
                label: "All",
              },
            ],
            headerStyle: headerStyleObj,
            searchFieldStyle: searchFieldStyleObj,
          }}
        />{" "}
      </div>

      <Modal
        style={{ zIndex: "100px" }}
        className="dropdown-toggle-split w-100 "
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            Action Tracking Emails
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addData}>
            <Form.Label>
              <h5 style={{ color: "#002564" }} p={2} className="text-lg-start">
                Activity<sup>*</sup>
              </h5>
            </Form.Label>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Col sm="10" className="w-100">
                <Form.Select
                  aria-label="Default select example"
                  value={areaData}
                  onChange={(e) => handleLine(e)}
                >
                  <option className="">Select Your Line: </option>
                  {getAreaOrtLineData.map((item) => {
                    return (
                      <option value={item.department}>{item.department}</option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Col sm="10" className="w-100">
                <Form.Select
                  aria-label="Default select example"
                  value={userEmail}
                  onChange={(e) => handleUser(e)}
                >
                  <option className="">Select Your User:</option>
                  {getOwnersData.map((item) => {
                    return <option value={item.email}>{item.email}</option>;
                  })}
                </Form.Select>
              </Col>
            </Form.Group>

            <div className="d-flex justify-content-between ">
              <Button
                className="w-25 mt-2 "
                onClick={handleClose}
                style={{ backgroundColor: "grey", border: "none" }}
              >
                Close
              </Button>

              {isDisabled ? (
                <Button
                  className="w-25 mt-2"
                  //   disabled={isDisabled}
                  type="submit"
                  onClick={handleAddQuestion}
                  style={{ backgroundColor: "#002564", border: "none" }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  className="w-25 mt-2 "
                  type="submit"
                  onClick={handleAddQuestion}
                  style={{ backgroundColor: "#002564", border: "none" }}
                >
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EmailConfig;
