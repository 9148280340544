import { createSlice } from "@reduxjs/toolkit";

const AdminQuestionMPSSlice = createSlice({
  name: "AdminQuestionMPS",
  initialState: {
    AdminQuestionMPS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET QuestionMPS
    getQuestionMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getQuestionMPSSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminQuestionMPS = action.payload;
    },
    getQuestionMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE QuestionMPS
    
  },
});

export const {
  getQuestionMPSStart,
  getQuestionMPSSuccess,
  getQuestionMPSFailure,
 
} = AdminQuestionMPSSlice.actions;
export default AdminQuestionMPSSlice.reducer;
