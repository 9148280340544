import { createSlice } from "@reduxjs/toolkit";

const AdminQuestionDHSlice = createSlice({
  name: "AdminQuestionDH",
  initialState: {
    AdminQuestionDH: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET QuestionDH
    getQuestionDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getQuestionDHSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminQuestionDH = action.payload;
    },
    getQuestionDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE QuestionDH
    
  },
});

export const {
  getQuestionDHStart,
  getQuestionDHSuccess,
  getQuestionDHFailure,
 
} = AdminQuestionDHSlice.actions;
export default AdminQuestionDHSlice.reducer;
