import { toast } from "react-toastify";
import { userRequest } from "../../apiRoutes/apiRoutes";
import { getLineOrAreaData, getOwnersData } from "../reducers/GlobalReducer";

export const getOwners = async (dispatch) => {
  const res = await userRequest.get(
    "https://actived.finehhdigital.com/api/users/getUsers"
  );
  dispatch(getOwnersData(res.data));
  return res;
};
export const getAreaAndlineData = async (dispatch) => {
  const res = await userRequest.get(
    "https://defect-b.finehhdigital.com/api/departments/getDepartment"
  );
  console.log("response From Line>>>>", res.data.department);
  dispatch(getLineOrAreaData(res.data.department));
  return res;
};
