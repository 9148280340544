import MaterialTable from "material-table";
import React, { useState, useEffect } from "react";
import { domain, userRequest } from "../../apiRoutes/apiRoutes";

const TableForArea = () => {
  const [areaData, setAreaData] = useState([]);
  // console.log("areaData >>>", areaData);
  const getAreaData = async () => {
    let result = await userRequest.get(`${domain}generalRouter/getArea`);
    setAreaData(result.data.payload.result);
  };
  //  const dispatch = useDispatch();
  useEffect(() => {
    getAreaData();
  }, []);

  const columns = [
    {
      title: "Line Name From Defect Hanlding System",
      field: "department",
      cellStyle: {
        fontSize: "12px",
      },
    },
  ];

  return (
    <div className="material-table-parent">
      <MaterialTable
        style={{
          whiteSpace: "nowrap",
          width: "100%",
          boxShadow: "0 0 0 0 #fff",

        }}
        title=""
        columns={columns}
        data={areaData}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,

          filtering: true,
          headerStyle: { color: "#112564", backgroundColor: "#ffffff" },
          searchFieldStyle: {
            backgroundColor: "#ffffff",
            borderBottom: "0px",
            borderRadius: "10px",
            padding: "4px",
          },
        }}
      />
    </div>
  );
};

export default TableForArea;
