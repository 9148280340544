import { createSlice } from "@reduxjs/toolkit";

const userDHSlice = createSlice({
  name: "userDH",
  initialState: {
    userDH: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET UserDH
    getUserDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserDHSuccess: (state, action) => {
      state.isFetching = false;
      state.userDH = action.payload;
    },
    getUserDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserDH
    deleteUserDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserDHSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserDH
    updateUserDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserDHSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserDH
    addUserDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserDHSuccess: (state, action) => {
      state.isFetching = false;
      // state.userDH.push(action.payload);
    },
    addUserDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserDHStart,
  getUserDHSuccess,
  getUserDHFailure,
  deleteUserDHStart,
  deleteUserDHSuccess,
  deleteUserDHFailure,
  updateUserDHStart,
  updateUserDHSuccess,
  updateUserDHFailure,
  addUserDHStart,
  addUserDHSuccess,
  addUserDHFailure,
} = userDHSlice.actions;
export default userDHSlice.reducer;
