import { toast } from "react-toastify";
import { createEmailLLFailure, createEmailLLStart, createEmailLLSuccess, deleteEmailLLFailure, deleteEmailLLStart, deleteEmailLLSuccess, getLLEmailFailure, getLLEmailStart, getLLEmailSuccess, updateEmailLLFailure, updateEmailLLStart, updateEmailLLSuccess } from "../reducers/EmailReducer";
import { CREATE_LINE_LEADER_EMAIL, DELETE_LINE_LEADER_EMAIL, SELECT_LINE_LEADER_EMAIL, UPDATE_LINE_LEADER_EMAIL, userRequest } from "../../apiRoutes/apiRoutes";

export const getLLEmail = async (dispatch) => {
    dispatch(getLLEmailStart());
    try {
      const res = await userRequest.get(`${SELECT_LINE_LEADER_EMAIL}`);
      console.log("response from get Line Leader Email ==>", res);
      dispatch(getLLEmailSuccess()); 
      return res;
    } catch (error) {
      console.log("error from User EmailFailure >>>", error);
      toast.error(" Server Error ");
      dispatch(getLLEmailFailure());
      return error;
    }
  };
  export const createEmailLL = async (dispatch, inputData) => {
    console.log("inputData>>>>", inputData);
    dispatch(createEmailLLStart());
    try {
        const result = await userRequest.post(`${CREATE_LINE_LEADER_EMAIL}`, inputData);
          console.log("result from createQuestionSH fnction", result);
      dispatch(createEmailLLSuccess(result.data.payload));
      toast.success("Inserted Successfully");
      return result;
    } catch (err) {
      // // console.log("error from catch", err);
      toast.error("Server Error");
      dispatch(createEmailLLFailure());
    }
  };
  
  //this function has API call in which you can update question from Email LL
  export const updateEmailLL = async (userData, dispatch) => {
    dispatch(updateEmailLLStart());
    // // console.log("userData >>>", userData);
    console.log("response from updtae defect handling question", userData);
    try {
      const res = await userRequest.post(`${UPDATE_LINE_LEADER_EMAIL}`, userData);
      console.log("response from updtae defect handling question", res);
      dispatch(updateEmailLLSuccess(res.data));
      toast.success("Updated Successfully");
      return res;
    } catch (error) {
      // // console.log("error from catch block", error);
      dispatch(updateEmailLLFailure());
      toast.error("Server Error.........");
      return error;
    }
  };
  //this function has API call in which you can delete question from admin LL
  
  export const deleteEmailLL = async (id, dispatch) => {
    dispatch(deleteEmailLLStart());
    try {
      const res = await userRequest.post(`${DELETE_LINE_LEADER_EMAIL}`, { id });
      dispatch(deleteEmailLLSuccess(res.data));
      toast.success("Deleted Successfully");
      return res;
    } catch (error) {
      // // console.log("error from catch block", error);
      dispatch(deleteEmailLLFailure());
      toast.error("Server Error");
    }
  };
  