import { createSlice } from "@reduxjs/toolkit";

const HomeSlice = createSlice({
  name: "Home",
  initialState: {
    DH: [],
    CL: [],
    MPS: [],
    DDS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET DH
    getDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDHSuccess: (state, action) => {
      state.isFetching = false;
      state.DH = action.payload;
    },
    getDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //GET CL
    getCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getCLSuccess: (state, action) => {
      state.isFetching = false;
      state.CL = action.payload;
    },
    getCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //GET MPS
    getMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getMPSSuccess: (state, action) => {
      state.isFetching = false;
      state.MPS = action.payload;
    },
    getMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //GET DDS
    getDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getDDSSuccess: (state, action) => {
      state.isFetching = false;
      state.DDS = action.payload;
    },
    getDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getDHStart,
  getDHSuccess,
  getDHFailure,
  getCLStart,
  getCLSuccess,
  getCLFailure,
  getDDSStart,
  getDDSSuccess,
  getDDSFailure,
  getMPSStart,
  getMPSSuccess,
  getMPSFailure,
} = HomeSlice.actions;
export default HomeSlice.reducer;
