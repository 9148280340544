import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionDDS } from "../../store/redux-apis/adminDDSApiCall";
import CircularProgress from "@mui/material/CircularProgress";

import {
  createUserDataDDS,
  selectUserDataDDS,
} from "../../store/redux-apis/userDDSApiCalls";
import _ from "lodash";
import moment from "moment";

// import Tooltip from "../../components/Tooltip/HoverTooltip";
import MaterialTable from "material-table";
import { selectUserDataDH } from "../../store/redux-apis/userDHApiCalls";
import { domain, userRequest } from "../../apiRoutes/apiRoutes";
import jwt from "jsonwebtoken";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";

const headerStyleObj = {
  backgroundColor: "#002564",
  color: "#EAEDF7",
  borderBottom: "#E4E6EF",
  borderBottomWidth: "1px",
  borderBottomStyle: "dashed",
  fontWeight: "600",
  fontSize: "12px",
};

const searchFieldStyleObj = {
  backgroundColor: "#EAEDF7",
  borderBottom: "0px",
  borderRadius: "10px",
  padding: "4px",
};

export const DailyDirection = () => {
  const [areaName, setAreaName] = useState("");
  const [activity, setActivity] = useState("");
  const [areaData, setAreaData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [userId, setUserId] = useState();

  // isState k andar data filter ho k jarha ha
  const [filterDataForUser, setfilterDataForUser] = useState([]);

  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  useEffect(() => {
    getQuestionDDS(dispatch);
    selectUserDataDDS(dispatch);
  }, [dispatch, isRefresh]);

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    setUserName(decoded.payload.username);
    setUserId(decoded.payload.id);

    getAreaData();
  }, []);
  const getAreaData = async () => {
    let result = await userRequest.get(`${domain}generalRouter/getArea`);
    // console.log(result.data.payload.result);
    setAreaData(result.data.payload.result);
  };
  const handleArea = (e) => {
    setAreaName(e.target.value);
  };

  // ya use selector nichay handle activity k bd use hrha ha filter krne k liye
  let adminForDDS = useSelector((state) => state.adminDDS.adminDDS);
  //ya submission k bd jo score nzr aiga  us ka ha kam ha
  var getSubmittedData = useSelector((state) => state.userDDS.userDDS);
  // console.log(getSubmittedData);

  var separateWithDate = [];
  separateWithDate.push(_.groupBy(getSubmittedData, "userId"));
  // console.log(separateWithDate);
  var newSeparateWithDate = separateWithDate[0];
  // console.log(newSeparateWithDate);

  let uniqueUsers = [];
  Object.entries(newSeparateWithDate).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    uniqueUsers.push({
      userId: item[0],
      data: item[1],
    });
  });

  // console.log(uniqueUsers);

  let uniqueAreas = [];

  Object.entries(uniqueUsers).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    var separateWithArea = [];
    separateWithArea.push(_.groupBy(item[1].data, "areaName"));
    // console.log("separateWithArea >>", separateWithArea[0]);
    Object.entries(separateWithArea[0]).forEach((area) => {
      // console.log("area >>>", area);
      uniqueAreas.push({
        userId: item[1].userId,
        areaName: area[0],
        data: area[1],
      });
    });
    // console.log(separateWithArea);
  });

  let uniqueDate = [];

  Object.entries(uniqueAreas).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    var seperatWithDate = [];
    seperatWithDate.push(_.groupBy(item[1].data, "submittedDate"));
    // console.log("separateWithArea >>",separateWithArea[0]);
    Object.entries(seperatWithDate[0]).forEach((date) => {
      uniqueDate.push({
        userId: item[1].userId,
        areaName: item[1].areaName,
        submittedDate: date[0],
        data: date[1],
      });
    });
  });

  // console.log("uniqueDate", uniqueDate);
  let finalScoreArray = [];

  uniqueDate.forEach((unique, index) => {
    let questionsArray = unique.data;
    let totalScore = 0;
    let totalQuestion = questionsArray.length * 2;
    // console.log("unique >>>", unique);
    let scoreData = {};
    questionsArray.some((ques, i) => {
      // console.log("ques.answer", ques.answer);
      let submittedBy = ques.submittedBy;
      let Activity = ques.flowChartDesc;
      if (ques.answer == 1) {
        totalScore++;
        scoreData = {
          percentage: ((totalScore / totalQuestion) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
          Activity: Activity,
        };
      }
      if (ques.answer == 2) {
        totalScore = totalScore + 2;
        scoreData = {
          percentage: ((totalScore / totalQuestion) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
          Activity: Activity,
        };
      } else {
        scoreData = {
          percentage: ((totalScore / totalQuestion) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
          Activity: Activity,
        };
      }
    });
    // console.log("scoreData >>>", scoreData);
    finalScoreArray.push({
      userId: unique.userId,
      areaName: unique.areaName,
      submittedBy: scoreData.submittedBy,
      Activity: scoreData.Activity,
      percentage: scoreData.percentage + "%",

      // percentage: ((totalScore / totalQuestion) * 100).toFixed(2) + "%",
      // percentage: scoreData.percentage.toFixed(2) + "%",
      submittedDate: unique.submittedDate,
      totalScore: scoreData.totalScore,
      totalQuestion: scoreData.totalQuestion,

      data: questionsArray,
    });
  });

  // console.log("finalScoreArray>>>", finalScoreArray);

  var tableDataForAdminDDS = filterDataForUser.map((o) => ({ ...o }));

  const handleActivity = (e) => {
    setActivity(e.target.value);
    let isAlreadySubmitted = false;
    if(getSubmittedData.length >0 ){
    getSubmittedData.forEach((item) => {
      if (
        item.areaName === areaName &&
        moment(item.submittedDate).format("YYYY-MM-DD") ===
          moment().format("YYYY-MM-DD") &&
        item.flowChartDesc === e.target.value &&
        item.submittedBy === userName
      ) {
        toast.error("For This Area HealthCheck Already Done ");
        isAlreadySubmitted = true;

        return handleClose();
      }
    })
  }else{
    
  }
    if (isAlreadySubmitted) {
      setActivity("");
      setAreaName("");
      return;
    }
    // filterTableData = adminForDDS
    const filter = adminForDDS.filter((f) => f.flowChartDesc == e.target.value);
    setfilterDataForUser(filter);
  };
  // ya user jb filkaraiga us table k liye ha ustad-e-muhtaram
  // var tableDataForAdminDDS = filterDataForUser.map((o) => ({ ...o }));

  let options = [
    { name: "Daily Line Meeting", value: "Daily Line Meeting" },
    {
      name: "Daily Department/Module Meeting",
      value: "Daily Department/Module Meeting",
    },
  ];

  var areaOption = areaData.map((item) => ({
    name: item.department,
    value: item.department,
  }));
  const handleOnSubmit = async (changes) => {
    // console.log("changes=====>", changes);
    const newValues = Object.values(changes);
    const newData = newValues.map((item) => item.newData);
    // console.log(newData);
    let newObj = {
      ...newData,
      subittedBy: userName,
      userId: userId,

      submittedData: moment().format("YYYY-MM-DD "),
      areaName: areaName,
    };
    // console.log(newObj);
    let res = await createUserDataDDS(dispatch, newObj);
    // console.log(res);

    if (res) {
      selectUserDataDH(dispatch);
      setIsRefresh((preValue) => !preValue);
      handleClose();
      setAreaName("");
      setActivity("");
    }
    // setfilterDataForUser("")
    // setFilterTableData("")
  };

  // const columns = ;
  const scoreColumns = [
    {
      title: "Line",
      field: "areaName",
      cellStyle: { fontSize: "12px" },
      headerStyle: { fontSize: "12px" },
    },
    {
      title: "Activity",
      field: "Activity",
      cellStyle: { fontSize: "12px" },
      headerStyle: { fontSize: "12px" },
    },
    {
      title: "SubmittedBy",
      field: "submittedBy",
      cellStyle: { fontSize: "12px" },
      headerStyle: { fontSize: "12px" },
    },
    {
      title: "Submitted Date",
      field: "submittedDate",
      cellStyle: { fontSize: "12px" },
      headerStyle: { fontSize: "12px" },
    },
    {
      title: "Score",
      field: "percentage",
      cellStyle: { fontSize: "12px" },
      headerStyle: { fontSize: "12px" },
    },
  ];
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            DDS DMS Health Check
          </h2>
          <BreadCrumbs first="DailyDirectionSettings" />
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn-icon-text my-2 me-2 btn btn-white"
            onClick={handleShow}
          >
            <AddToPhotosOutlinedIcon
              style={{ fontSize: "16px" }}
              className="me-2"
            />
            Health Check
          </button>
          <button className="btn-icon-text my-2 me-2 btn btn-white bg-success">
            <Link
              to={"/dashboard/AdministrationDailyDirection"}
              className="w-100 text-white"
              style={{ textDecoration: "none" }}
            >
              Administration
            </Link>
          </button>
        </div>
      </div>

      <div>
        {/* <MaterialTableComp rows={finalScoreArray}
          columns={scoreColumns}
          dpColums={columns} /> */}
        <div className="material-table-parent-div d-flex w-100">
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h1
                    style={{
                      marginTop: "6%",
                      top: "16%",
                      marginLeft: "-70px",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </h1>
                ),
              },
            }}
            columns={scoreColumns}
            data={finalScoreArray}
            detailPanel={(rowData) => {
              let tableDataForDDS =
                rowData.data && rowData.data.map((o) => ({ ...o }));
              console.log("tableDataForDDS >>>", tableDataForDDS);

              return (
                <div className="material-table-parent-div">
                  <MaterialTable
                    // columns={columns}
                    columns={[
                      {
                        title: "Line",
                        field: "areaName",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Activity",
                        field: "flowChartDesc",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                        render: (row) => {
                          return (
                            <div>
                              <OverlayTrigger
                                // trigger="hover"
                                trigger={["hover", "hover"]}
                                overlay={
                                  <Popover>
                                    <Popover.Body style={{ fontSize: "12px" }}>
                                      {row.flowChartDesc}
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                <div
                                  className="d-flex justify-content-center align-items-center rounded-circle text-white text-center "
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <InfoOutlinedIcon
                                    color="success"
                                    sx={{ fontSize: 20 }}
                                    fontSize="small"
                                  />
                                </div>
                              </OverlayTrigger>
                            </div>
                          );
                        },
                      },
                      {
                        title: "Key Points",
                        field: "healthAuditQues",
                        // cellStyle: {
                        //   whiteSpace: "nowrap",
                        // },
                        cellStyle: {
                          width: "60%",
                          maxWidth: "60%",
                          fontSize: "12px",
                        },
                        headerStyle: {
                          width: "60%",
                          maxWidth: "60%",
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Answer",
                        field: "answer",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Comment",
                        field: "comment",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                    ]}
                    data={tableDataForDDS && tableDataForDDS}
                    options={{
                      filtering: false,
                      showTitle: false,
                      headerStyle: headerStyleObj,
                      search: false,
                      exportButton: true,
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },
                      exportFileName:
                        "DDS_DMS_Detail_" +
                        moment().format("YYYY-MM-DDTHHmmss"),

                      pageSizeOptions: [
                        5,
                        10,
                        20,
                        {
                          value: tableDataForDDS.length,
                          label: "All",
                        },
                      ],
                    }}
                  />
                </div>
              );
            }}
            options={{
              filtering: true,
              showTitle: false,
              search: false,
              exportButton: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportFileName: "DDS_DMS_" + moment().format("YYYY-MM-DDTHHmmss"),

              headerStyle: headerStyleObj,
              searchFieldStyle: searchFieldStyleObj,
              pageSizeOptions: [
                5,
                10,
                20,
                {
                  value: finalScoreArray && finalScoreArray.length,
                  label: "All",
                },
              ],
            }}
          />
        </div>
      </div>

      <Modal
        style={{ zIndex: "100px" }}
        className="dropdown-toggle-split w-100"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            DailyDirection Health Check:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                LineName:
              </Form.Label>
              <Col sm="10">
                <Form.Select
                  aria-label="Default select example"
                  value={areaName}
                  onChange={(e) => handleArea(e)}
                >
                  <option>Select Your Line</option>
                  {areaOption.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
            {areaName ? (
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Form.Label column sm="2">
                  Activity
                </Form.Label>
                <Col sm="10">
                  <Form.Select
                    aria-label="Default select example"
                    value={activity}
                    onChange={(e) => handleActivity(e)}
                  >
                    <option>Select Activity</option>
                    {options.map((item) => {
                      return <option value={item.value}>{item.name}</option>;
                    })}
                  </Form.Select>
                </Col>
              </Form.Group>
            ) : null}
          </Form>
          {activity ? (
            <div className="dropdown-toggle-split w-100 forscrollbar">
              <MaterialTable
                style={{
                  // whiteSpace: "nowrap",
                  backgroundColor: "#fff",
                  borderRadius: "0",
                  height: "67vh ",
                  overflow: "scroll",
                  overflowX: "hidden",
                }}
                title="Editable Preview"
                columns={[
                  // { title: "Line", field: "areaName", editable: "never" },

                  {
                    title: "Activity",
                    field: "flowChartDesc",
                    editable: "never",
                    cellStyle: {
                      width: "60%",
                      maxWidth: "60%",
                      fontSize: "12px",
                    },
                    headerStyle: {
                      width: "60%",
                      maxWidth: "60%",
                      fontSize: "12px",
                    },
                  },
                  {
                    title: "Key Points",
                    field: "healthAuditQues",
                    editable: "never",
                    cellStyle: {
                      width: "60%",
                      maxWidth: "60%",
                      fontSize: "12px",
                    },
                    headerStyle: {
                      width: "60%",
                      maxWidth: "60%",
                      fontSize: "12px",
                    },
                  },
                  {
                    title: "Answer",
                    field: "Answer",
                    lookup: {0:0, 1: 1, 2: 2 },
                    cellStyle: {
                      fontSize: "12px",
                    },
                    headerStyle: {
                      fontSize: "12px",
                    },
                  },
                  {
                    title: "Comments",
                    field: "comment",
                    cellStyle: {
                      fontSize: "12px",
                    },
                    headerStyle: {
                      fontSize: "12px",
                    },
                  },
                ]}
                data={tableDataForAdminDDS}
                editable={{
                  isDeleteHidden: (row) => row,
                  onBulkUpdate: (changes) =>
                    new Promise((resolve, reject) => {
                      handleOnSubmit(changes);
                      setTimeout(() => {
                        setIsRefresh((preValue) => !preValue);
                        resolve();
                      }, 1000);
                    }),
                }}
                options={{
                  filtering: false,
                  search: false,
                  showTitle: false,
                  headerStyle: {
                    color: "#0e0e0e",
                    borderBottom: "#E4E6EF",
                    borderBottomWidth: "1px",
                    borderBottomStyle: "dashed",
                    fontWeight: "600",
                    fontSize: "12px",
                    backgroundColor: "#fff",
                  },
                  actionsColumnIndex: -1,
                  // pageSizeOptions: [5, 10, 20, filterTableData.length],
                  paging: false,
                  pageSize: tableDataForAdminDDS.length,
                  // searchFieldStyle: {
                  //   backgroundColor: "#EAEDF7",
                  //   borderBottom: "0px",
                  //   borderRadius: "10px",
                  //   padding: "4px",
                  // },
                }}
              />
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "60vh" }}
            >
              <h3 className="text-center">
                Please Select Area Name And Activity...
              </h3>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
