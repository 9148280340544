import {
  CREATE_MPandS_USER,
  SELECT_MPandS_USER,
  userRequest,
} from "../../apiRoutes/apiRoutes";
import {
  getUserMPSStart,
  getUserMPSFailure,
  getUserMPSSuccess,
  addUserMPSFailure,
  addUserMPSStart,
  addUserMPSSuccess,
} from "../reducers/userMPSReducer";
import { toast } from "react-toastify";

export const createUserDataMpandS = async (dispatch, inputData) => {
  dispatch(addUserMPSStart());
  try {
    const result = await userRequest.post(`${CREATE_MPandS_USER}`, inputData);
    console.log("result from createQuestionDH", result);
    // toast.success("Feedback Added Successfully");

    dispatch(addUserMPSSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");

    dispatch(addUserMPSFailure());
  }
};
export const selectUserDataMPandS = async (dispatch) => {
  dispatch(getUserMPSStart());
  try {
    const result = await userRequest.get(`${SELECT_MPandS_USER}`);
    // console.log("result from getQuestionDH", result);
    // toast.success("Successfully")
    dispatch(getUserMPSSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getUserMPSFailure());
  }
};
