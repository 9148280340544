import {
  addAdmnCILStart,
  addAdmnCILFailure,
  addAdmnCILSuccess,
  getAdminCILStart,
  getAdminCILFailure,
  getAdminCILSuccess,
  updateAdminCILStart,
  updateAdminCILFailure,
  updateAdminCILSuccess,
  deleteAdminCILStart,
  deleteAdminCILSuccess,
  deleteAdminCILFailure
} from "../reducers/adminCILReducer";
import {
  ADD_QUES_CIL,
  UPDATE_QUES_CIL,
  DELETE_QUES_CIL,
  SELECT_QUES_CIL,
  userRequest
} from "../../apiRoutes/apiRoutes";
import { toast } from "react-toastify";

export const getQuestionCIL = async (dispatch) => {
  dispatch(getAdminCILStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_CIL}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminCILSuccess(res.data.payload));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminCILFailure());
  }
};

export const createQuestionCIL = async (dispatch, inputData) => {
  dispatch(addAdmnCILStart());
  try {
    const result = await userRequest.post(`${ADD_QUES_CIL}`, inputData);
    // console.log("result from createQuestionDH",result);
    dispatch(addAdmnCILSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdmnCILFailure());
  }
};
export const updateQuestionCIL = async (userData, dispatch) => {
  dispatch(updateAdminCILStart());
  try {
    const res = await userRequest.post(`${UPDATE_QUES_CIL}`, userData);
    // console.log("response from updtae defect handling question", res);
    dispatch(updateAdminCILSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminCILFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionCIL = async (id, dispatch) => {
  dispatch(deleteAdminCILStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_CIL}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminCILSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminCILFailure());
    toast.error("Server Error");
  }
};
