import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import MaterialTable from "material-table";
import {
  createUserDataMpandS,
  selectUserDataMPandS,
} from "../../store/redux-apis/userMPandSCalls";
import { domain, userRequest } from "../../apiRoutes/apiRoutes";
import jwt from "jsonwebtoken";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import { getQuestionMPandS } from "../../store/redux-apis/adminMPandSCalls";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";
import Spinner from "../../components/Spinner/Spinner";
import CircularProgress from "@mui/material/CircularProgress";

const headerStyleObj = {
  backgroundColor: "#002564",
  color: "#EAEDF7",
  borderBottom: "#E4E6EF",
  borderBottomWidth: "1px",
  borderBottomStyle: "dashed",
  fontWeight: "600",
  fontSize: "12px",
};

const searchFieldStyleObj = {
  backgroundColor: "#EAEDF7",
  borderBottom: "0px",
  borderRadius: "10px",
  padding: "4px",
};
export const Mps = () => {
  const [filterTableData, setFilterTableData] = useState([]);
  const [areaName, setAreaName] = useState("");
  const [userId, setUserId] = useState();
  const [areaData, setAreaData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);

  const [userName, setUserName] = useState("");
  // console.log("userName: " + userName);
  const [show, setShow] = useState(false);

  let adminForMPS = useSelector((state) => state.adminMPS.adminMPS);
  var tableDataForAdminMPS = adminForMPS.map((o) => ({ ...o }));
  // console.log("tableDataForAdminMPS", tableDataForAdminMPS);
  let userForMPS = useSelector((state) => state.userMPS.userMPS);
  //Loading
  let isSuccess = useSelector((state) => state.userMPS.isSuccess);
  // console.log("isSuccess >>>", isSuccess);
  let tableDataForUserMPS = userForMPS.map((o) => ({ ...o }));
  // console.log("tableDataForUserMPS >>>>", tableDataForUserMPS);

  var separateWithDate = [];
  separateWithDate.push(_.groupBy(tableDataForUserMPS, "userId"));
  var newSeparateWithDate = separateWithDate[0];

  let uniqueUsers = [];
  Object.entries(newSeparateWithDate).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    uniqueUsers.push({
      userId: item[0],
      data: item[1],
    });
  });
  // console.log("uniqueUsers >>>", uniqueUsers);

  let uniqueAreas = [];

  Object.entries(uniqueUsers).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    var separateWithArea = [];
    separateWithArea.push(_.groupBy(item[1].data, "areaName"));
    // console.log("separateWithArea >>",separateWithArea[0]);
    Object.entries(separateWithArea[0]).forEach((area) => {
      // console.log("area >>>", area);
      uniqueAreas.push({
        userId: item[1].userId,
        areaName: area[0],
        data: area[1],
      });
    });
  });
  // console.log("uniqueAreas >>>", uniqueAreas);

  let uniqueDate = [];

  Object.entries(uniqueAreas).forEach((item, index, arr) => {
    // console.log("item >>>>", item);
    var seperatWithDate = [];
    seperatWithDate.push(_.groupBy(item[1].data, "submittedDate"));
    // console.log("separateWithArea >>",separateWithArea[0]);
    Object.entries(seperatWithDate[0]).forEach((date) => {
      uniqueDate.push({
        userId: item[1].userId,
        areaName: item[1].areaName,
        submittedDate: date[0],
        data: date[1],
      });
    });
  });
  // console.log("uniqueData>>>", uniqueDate);
  // var totalquestion = uniqueDate.length;
  // console.log("totalquestion", totalquestion);

  let finalScoreArray = [];

  uniqueDate.forEach((unique, index) => {
    let questionsArray = unique.data;
    let totalScore = 0;
    let totalQuestion = questionsArray.length;
    // console.log("unique >>>", unique);
    let scoreData = {};
    questionsArray.some((ques, i) => {
      // console.log("ques.answer", ques.answer);
      let submittedBy = ques.submittedBy;
      if (ques.answer === 1) {
        totalScore++;
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      } else if (ques.answer === 2) {
        totalScore += 2;
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      } else if (ques.answer === 3) {
        totalScore += 3;
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      } else if (ques.answer === 4) {
        totalScore += 4;
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      } else if (ques.answer === 5) {
        totalScore += 5;
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      } else {
        scoreData = {
          percentage: ((totalScore / (totalQuestion * 5)) * 100).toFixed(2),
          submittedDate: unique.submittedDate,
          totalScore: totalScore,
          totalQuestion: totalQuestion,
          submittedBy: submittedBy,
        };
      }

      // if (ques.answer == 1) {
      //   totalScore++;
      //   scoreData = {
      //     // percentage: ((totalScore / totalQuestion) * 100).toFixed(2),
      //     percentage: ((totalScore / (totalQuestion)) * 100).toFixed(2),
      //     submittedDate: unique.submittedDate,
      //     totalScore: totalScore,
      //     totalQuestion: totalQuestion,
      //     submittedBy: submittedBy,
      //   };
      // } else {
      //   scoreData = {
      //     // percentage: ((totalScore / totalQuestion) * 100).toFixed(2),
      //     percentage: ((totalScore / (totalQuestion)) * 100).toFixed(2),

      //     submittedDate: unique.submittedDate,
      //     totalScore: totalScore,
      //     totalQuestion: totalQuestion,
      //     submittedBy: submittedBy,
      //   };
      // }
    });

    // console.log("scoreData >>>", scoreData);
    finalScoreArray.push({
      userId: unique.userId,
      areaName: unique.areaName,
      submittedBy: scoreData.submittedBy,
      percentage: scoreData.percentage + "%",

      // percentage: ((totalScore / totalQuestion) * 100).toFixed(2) + "%",
      // percentage: scoreData.percentage.toFixed(2) + "%",
      submittedDate: unique.submittedDate,
      totalScore: scoreData.totalScore,
      totalQuestion: scoreData.totalQuestion,
      data: questionsArray,
    });
  });
  // console.log("finalScoreArray >>>", finalScoreArray);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  useEffect(() => {
    getQuestionMPandS(dispatch);
    selectUserDataMPandS(dispatch);
    // setFilterTableData("")
  }, [dispatch, isRefresh]);

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt.decode(token, { complete: true });
    setUserName(decoded.payload.username);
    setUserId(decoded.payload.id);
    getAreaData();
  }, []);

  const getAreaData = async () => {
    let result = await userRequest.get(`${domain}generalRouter/getArea`);
    // console.log(result.data.payload.result);
    setAreaData(result.data.payload.result);
  };
  const handleArea = (e) => {
    setAreaName(e.target.value);
    let selectedArea = e.target.value;
    if (tableDataForUserMPS.length > 0) {
      tableDataForUserMPS.forEach((item) => {
        if (
          item.areaName === selectedArea &&
          moment(item.submittedDate).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD") &&
          item.submittedBy === userName
        ) {
          // console.log("item.submittedDate" , item.submittedDate);
          toast.error("For This Area HealthCheck Already Done ");

          return handleClose();
        } else {
          // console.log("else condition running >>>>");
          setFilterTableData(tableDataForAdminMPS);
        }
      });
    } else {
      // console.log("tableDataForAdminMPS >>>", tableDataForAdminMPS);
      setFilterTableData(tableDataForAdminMPS);
    }
  };

  var areaOption = areaData.map((item) => ({
    name: item.department,
    value: item.department,
  }));

  const handleOnSubmit = async (changes) => {
    const newValues = Object.values(changes);
    const newData = newValues.map((item) => item.newData);
    // if (filterTableData.length != newData.length) {
    //   return toast.error("please Complete the form ");
    // }
    if ((filterTableData.length = newData.length)) {
      // console.log("newData DSSD", newData);

      let newObj = {
        ...newData,
        areaName: areaName,
        subittedBy: userName,
        userId: userId,
        submittedData: moment().format("YYYY-MM-DD"),
        // submittedData: new Date(),
      };
      // console.log("submittedbydata :", newObj);
      // console.log("filtertablkedata :", filterTableData.length);
      let res = await createUserDataMpandS(dispatch, newObj);
      // console.log(res);

      selectUserDataMPandS(dispatch);
      setIsRefresh((preValue) => !preValue);
      handleClose();
      setFilterTableData([]);
      setAreaName("");
    }
  };

  // const columns = ;
  // const scoreColumns = ;
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            MP&S DMS Health Check
          </h2>
          <BreadCrumbs first="Mps" />
        </div>
        <div className="d-flex justify-content-end">
          <button
            type="button"
            className="btn-icon-text my-2 me-2 btn btn-white"
            onClick={handleShow}
          >
            <AddToPhotosOutlinedIcon
              style={{ fontSize: "16px" }}
              className="me-2"
            />
            Health Check
          </button>
          <button className="btn-icon-text my-2 me-2 btn btn-white bg-success">
            <Link
              to={"/dashboard/AdministrationMps"}
              className="w-100 text-white"
              style={{ textDecoration: "none" }}
            >
              Administration
            </Link>
          </button>
        </div>
      </div>

      <div>
        <div className="material-table-parent-div d-flex w-100">
          <MaterialTable
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h1
                    style={{
                      marginTop: "6%",
                      top: "16%",
                      marginLeft: "-70px",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </h1>
                ),
              },
              toolbar: {
                exportTitle: "Export as CSV",
                exportAriaLabel: "Download ",
                exportCSVName: "Export as CSV",
              },
            }}
            columns={[
              {
                title: "Line",
                field: "areaName",
                cellStyle: { fontSize: "12px" },
                headerStyle: { fontSize: "12px" },
              },
              {
                title: "SubmittedBy",
                field: "submittedBy",
                cellStyle: { fontSize: "12px" },
                headerStyle: { fontSize: "12px" },
              },
              {
                title: "Submitted Date",
                field: "submittedDate",
                cellStyle: { fontSize: "12px" },
                headerStyle: { fontSize: "12px" },
              },
              {
                title: "Score",
                field: "percentage",
                cellStyle: { fontSize: "12px" },
                headerStyle: { fontSize: "12px" },
              },
            ]}
            data={finalScoreArray}
            detailPanel={(rowData) => {
              return (
                <div className="material-table-parent-div">
                  <MaterialTable
                    columns={[
                      {
                        title: "Description ",
                        field: "flowChartDesc",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Health Audit",
                        field: "healthAuditQues",

                        cellStyle: {
                          width: "60%",
                          maxWidth: "60%",
                          fontSize: "12px",
                        },
                        headerStyle: {
                          width: "60%",
                          maxWidth: "60%",
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Answer",
                        field: "answer",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                      {
                        title: "Comment",
                        field: "comment",
                        cellStyle: {
                          fontSize: "12px",
                        },
                        headerStyle: {
                          fontSize: "12px",
                        },
                      },
                    ]}
                    data={rowData.data}
                    options={{
                      filtering: false,
                      showTitle: false,
                      headerStyle: headerStyleObj,
                      search: false,
                      exportButton: true,
                      exportButton: {
                        csv: true,
                        pdf: false,
                      },
                      exportFileName:
                        "MPS_DMS_Detail_" +
                        moment().format("YYYY-MM-DDTHHmmss"),

                      pageSizeOptions: [
                        5,
                        10,
                        20,
                        {
                          value: rowData.data ? rowData.data.length : [],
                          label: "All",
                        },
                      ],
                    }}
                  />
                </div>
              );
            }}
            options={{
              filtering: true,
              showTitle: false,
              search: false,
              exportButton: true,
              exportButton: {
                csv: true,
                pdf: false,
              },
              exportFileName: "MPS_DMS_" + moment().format("YYYY-MM-DDTHHmmss"),

              headerStyle: headerStyleObj,
              searchFieldStyle: searchFieldStyleObj,
              pageSizeOptions: [
                5,
                10,
                20,
                {
                  value: finalScoreArray.length,
                  label: "All",
                },
              ],
            }}
          />
        </div>
      </div>

      <Modal
        style={{ zIndex: "100px" }}
        className="dropdown-toggle-split w-100"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            MP&S Health Check:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                LineName:
              </Form.Label>
              <Col sm="10">
                <Form.Select
                  aria-label="Default select example"
                  value={areaName}
                  onChange={(e) => handleArea(e)}
                >
                  <option>Select Your Line:</option>
                  {areaOption.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
          </Form>
          {areaName ? (
            <div className="dropdown-toggle-split w-100 forscrollbar">
              {isSuccess ? (
                <div
                  className="mx-5 my-5"
                  style={{ width: "50px", height: "50px", marginLeft: "50px" }}
                >
                  <Spinner />
                </div>
              ) : (
                <MaterialTable
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "0",
                    height: "67vh ",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                  title="Editable Preview"
                  columns={[
                    // { title: "Line", field: "areaName", editable: "never" },
                    {
                      title: "AddressTo",
                      field: "addressQuesTo",
                      editable: "never",
                      cellStyle: {
                        fontSize: "12px",
                      },
                      headerStyle: {
                        fontSize: "12px",
                      },
                    },
                    {
                      title: "Description",
                      field: "flowChartDesc",
                      editable: "never",
                      cellStyle: {
                        fontSize: "12px",
                      },
                      headerStyle: {
                        fontSize: "12px",
                      },
                    },
                    {
                      title: "Question",
                      field: "healthAuditQues",
                      editable: "never",
                      cellStyle: {
                        width: "60%",
                        maxWidth: "60%",
                        fontSize: "12px",
                      },
                      headerStyle: {
                        width: "60%",
                        maxWidth: "60%",
                        fontSize: "12px",
                      },
                    },
                    {
                      title: "Answer",
                      field: "Answer",
                      lookup: { 1: "1", 2: "2", 3: "3", 4: "4", 5: "5" },
                      cellStyle: {
                        fontSize: "12px",
                      },
                      headerStyle: {
                        fontSize: "12px",
                      },
                    },
                    {
                      title: "Comments",
                      field: "comment",
                      cellStyle: {
                        fontSize: "12px",
                      },
                      headerStyle: {
                        fontSize: "12px",
                      },
                    },
                  ]}
                  data={filterTableData}
                  editable={{
                    isDeleteHidden: (row) => row,
                    onBulkUpdate: (changes) =>
                      new Promise((resolve, reject) => {
                        handleOnSubmit(changes);
                        setTimeout(() => {
                          setIsRefresh((preValue) => !preValue);

                          resolve();
                        }, 1000);
                      }),
                  }}
                  options={{
                    filtering: false,
                    search: false,
                    showTitle: false,
                    headerStyle: {
                      color: "#0e0e0e",
                      borderBottom: "#E4E6EF",
                      borderBottomWidth: "1px",
                      borderBottomStyle: "dashed",
                      fontWeight: "600",
                      fontSize: "12px",
                      backgroundColor: "#fff",
                    },
                    actionsColumnIndex: -1,
                    // pageSizeOptions: [5, 10, 20, filterTableData.length],
                    paging: false,
                    pageSize: filterTableData.length,
                  }}
                />
              )}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "60vh" }}
            >
              <h3 className="text-center">Please Select Line Name...</h3>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};
