import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MaterialTable from "material-table";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import {
  createQuestionCIL,
  deleteQuestionCIL,
  getQuestionCIL,
  updateQuestionCIL,
} from "../../store/redux-apis/adminCILApiCalls";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeHtml } from "../../domUtils/domPurify";
// import { selectAdminQuestionCIL} from "../../store/redux-apis/clQuestionApiCalls";
// import { selectAdminQuestionCIL} from "../../store/redux-apis/cilQuestionApiCalls";

export const AdminCILDetailTable = () => {
  const headerStyleObj = {
    backgroundColor: "#ffffff",
    color: "#112564",
    borderBottom: "#E4E6EF",
    borderBottomWidth: "1px",
    borderBottomStyle: "dashed",
    fontWeight: "600",
    fontSize: "12px",
  };

  const searchFieldStyleObj = {
    backgroundColor: "#EAEDF7",
    borderBottom: "0px",
    borderRadius: "10px",
    padding: "4px",
  };
  const columns = [
    {
      title: "AddressTo",
      field: "addressQuesTo",
      cellStyle: {
        fontSize: "12px",
      },
      headerStyle: {
        fontSize: "12px",
      },
    },
    {
      title: "Flow Chart Description",
      field: "flowChartDesc",
      cellStyle: {
        width: "40%",
        maxWidth: "40%",
        fontSize: "12px",
      },
      headerStyle: {
        width: "40%",
        maxWidth: "40%",
        fontSize: "12px",
      },
    },

    {
      title: "Health Audit Questions & Required Observations",
      field: "healthAuditQues",
      cellStyle: {
        width: "40%",
        maxWidth: "40%",
        fontSize: "12px",
      },
      headerStyle: {
        width: "40%",
        maxWidth: "40%",
        fontSize: "12px",
      },
    },
  ];
  let adminForCIL = useSelector((state) => state.AdminCIL.AdminCIL);
  if (adminForCIL.length > 0) {
    var tableDataForAdminCIL = adminForCIL.map((o) => ({ ...o }));
  }

  const [show, setShow] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [flowChartDesc, setflowChartDesc] = useState("");
  const [addressQuestionTo, setaddressQuestionTo] = useState("");
  const [question, setQuestion] = useState("");

  const addData = (event) => {
    event.preventDefault();
  };
  const inputData = {
    areaName: "areaName",
    flowChartDesc: flowChartDesc,
    addressQuesTo: addressQuestionTo,
    questions: question,
  };

  // Sanitize Inputs from xss attacks
  const sanitizedHtmlflow = sanitizeHtml(flowChartDesc);
  const sanitizedHtmlAdd = sanitizeHtml(addressQuestionTo);
  const sanitizedHtmlQues = sanitizeHtml(question);

  const handleAddQuestion = async (e) => {
    console.log("inputData >>>", inputData);
    let res = await createQuestionCIL(dispatch, inputData);
    // console.log(res);
    setIsRefresh((preValue) => !preValue);
    setQuestion("");
    setaddressQuestionTo("");
    setflowChartDesc("");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getQuestionCIL(dispatch);
    // selectAdminQuestionCIL(dispatch);
  }, [dispatch, isRefresh]);

  return (
    <div>
      <div>
        <div className=" material-table-parent  w-100">
          <div className="d-flex flex-md-row-reverse pb-2">
            <button
              type="button"
              className="btn-icon-text my-2 me-2 btn btn-white bg-success text-white"
              onClick={handleShow}
            >
              <AddToPhotosOutlinedIcon
                style={{ fontSize: "16px" }}
                className="me-2"
              />
              Add Question
            </button>
          </div>
          <MaterialTable
            style={{
              zIndex: 0,
              boxShadow: "0 0 0 0 #fff",
            }}
            columns={columns}
            data={tableDataForAdminCIL}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...tableDataForAdminCIL];
                    const index = oldData.id;
                    dataUpdate[index] = newData;
                    updateQuestionCIL(dataUpdate[index], dispatch);
                    // getOrder(dispatch);
                    getQuestionCIL(dispatch);
                    setIsRefresh((preValue) => !preValue);

                    setShow(false);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const index = oldData.id;
                    deleteQuestionCIL(index, dispatch);
                    setShow(false);
                    getQuestionCIL(dispatch);
                    setIsRefresh((preValue) => !preValue);
                    resolve();
                  }, 1000);
                }),
            }}
            options={{
              // actionsColumnIndex: -1,
              exportButton: true,
              search: false,
              exportButton: false,

              showTitle: false,
              toolbar: false,
              filtering: true,
              pageSizeOptions: [
                5,
                10,
                20,
                {
                  value: tableDataForAdminCIL
                    ? tableDataForAdminCIL.length
                    : [],
                  label: "All",
                },
              ],
              headerStyle: headerStyleObj,
              searchFieldStyle: searchFieldStyleObj,
            }}
          />{" "}
        </div>
      </div>

      <Modal
        style={{ zIndex: "100px" }}
        className="dropdown-toggle-split w-100 "
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            New Question Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addData}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h5
                  style={{ color: "#002564" }}
                  p={2}
                  className="text-lg-start"
                >
                  Flow Chart Description<sup>*</sup>
                </h5>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Flow Chart Description"
                name="username"
                // ref={flowChartDescRef}
                value={sanitizedHtmlflow}
                onChange={(e) => setflowChartDesc(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h5
                  style={{ color: "#002564" }}
                  p={2}
                  className="text-lg-start"
                >
                  Address Question To<sup>*</sup>
                </h5>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Who will Do it"
                // ref={addressQuesToRef}
                value={sanitizedHtmlAdd}
                onChange={(e) => setaddressQuestionTo(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h5
                  style={{ color: "#002564" }}
                  p={2}
                  className="text-lg-start"
                >
                  Question<sup>*</sup>
                </h5>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="New Question"
                onChange={(e) => setQuestion(e.target.value)}
                value={sanitizedHtmlQues}
              />
            </Form.Group>
            <div className="d-flex justify-content-between ">
              <Button
                className="w-25 mt-2 "
                onClick={handleClose}
                style={{ backgroundColor: "grey", border: "none" }}
              >
                Close
              </Button>
              <Button
                className="w-25 mt-2 "
                type="submit"
                onClick={handleAddQuestion}
                style={{ backgroundColor: "#002564", border: "none" }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default AdminCILDetailTable;
