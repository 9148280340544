import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableForArea from "./TableforArea";
import { AdminDDSDetailTable } from "./AdminDDSDetailTable";


export const AdminDDSTabsComp = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  return (
    <Box className="w-100" sx={{ bgcolor: "background.paper", width: 500 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#112564", color: "#ffffff" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          // indicatorColor="inherit   "
          TabIndicatorProps={{ style: { background: "#ffffff" } }}
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="ADD HEALTH CHECK DETAILS" {...a11yProps(1)} />
          <Tab label=" LINE" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <AdminDDSDetailTable />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <TableForArea />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
