import { createSlice } from "@reduxjs/toolkit";

const userCLSlice = createSlice({
  name: "userCL",
  initialState: {
    userCL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET UserCL
    getUserCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserCLSuccess: (state, action) => {
      state.isFetching = false;
      state.userCL = action.payload;
    },
    getUserCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserCL
    deleteUserCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserCLSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserCL
    updateUserCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserCLSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserCL
    addUserCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserCLSuccess: (state, action) => {
      state.isFetching = false;
      // state.userCL.push(action.payload);
    },
    addUserCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserCLStart,
  getUserCLSuccess,
  getUserCLFailure,
  deleteUserCLStart,
  deleteUserCLSuccess,
  deleteUserCLFailure,
  updateUserCLStart,
  updateUserCLSuccess,
  updateUserCLFailure,
  addUserCLStart,
  addUserCLSuccess,
  addUserCLFailure,
} = userCLSlice.actions;
export default userCLSlice.reducer;
