import { createSlice } from "@reduxjs/toolkit";

const adminDDSSlice = createSlice({
  name: "adminDDS",
  initialState: {
    adminDDS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminDDS
    getAdminDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminDDSSuccess: (state, action) => {
      state.isFetching = false;
      state.adminDDS = action.payload;
    },
    getAdminDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminDDS
    deleteAdminDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminDDSSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminDDS
    updateAdminDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminDDSSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminDDS
    addAdmnDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdmnDDSSuccess: (state, action) => {
      state.isFetching = false;
      // state.adminDDS.push(action.payload);
    },
    addAdmnDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminDDSStart,
  getAdminDDSSuccess,
  getAdminDDSFailure,
  deleteAdminDDSStart,
  deleteAdminDDSSuccess,
  deleteAdminDDSFailure,
  updateAdminDDSStart,
  updateAdminDDSSuccess,
  updateAdminDDSFailure,
  addAdmnDDSStart,
  addAdmnDDSSuccess,
  addAdmnDDSFailure,
} = adminDDSSlice.actions;
export default adminDDSSlice.reducer;
