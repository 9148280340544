import React, { useEffect, useState } from "react";
// import _ from "lodash";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

export const Home = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    console.log("location.pathname >>", location.pathname);
    setTimeout(() => {
      history.push(location.pathname);
    }, 100);
  }, []);
  // const [isActive, setIsActive] = useState({
  //   DH: false,
  //   CL: false,
  //   DDS: false,
  //   MPS: false,
  //   CIL: false,
  // });

  return (
    <div className="material-table-parent-div material-table-parent-home h-100">
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Wellcome to DMS Health Checks
          </h2>
          <BreadCrumbs first="All Health Checks" />
        </div>
        {/* <div className="d-flex justify-content-end">
          <button
            className="btn-icon-text my-2 me-2 btn text-white bg-success"
            onClick={handleShow}
          >
            View Calender
          </button>
        </div> */}
      </div>
      <div className="mt-5">
        <div className="home-cards">
          <div className="hvr-shadow-radial">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/DefectHandling"}
            >
              <div className="col-lg-2 col-md-6  py-2 ">
                <div className="feature_item bg-white text-center">
                  <img src="/icons/dh.png" alt="DH" width="50" height="50" />
                  <h4 className="text-Card">DH DMS</h4>
                  {/* <p>DH System</p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/DailyDirectionSettings"}
            >
              <div className="col-lg-2 col-md-6  py-2 ">
                <div
                  className="feature_item bg-white text-center"
                  // style={{
                  //   background: "linear-gradient(100deg, #5AD0F2, #2DBDFA)",
                  // }}
                  // style={bgColor}
                >
                  <img src="/icons/dds.png" alt="DDS" width="50" height="50" />
                  <h4 className="text-Card">DDS DMS</h4>
                  {/* <p>DDS System</p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link style={{ textDecoration: "none" }} to={match.url + "/Mps"}>
              <div className="col-lg-2 col-md-6  py-2 ">
                <div className="feature_item bg-white text-center">
                  <img src="/icons/mps.png" alt="MPS" width="50" height="50" />
                  <h4 className="text-Card">MPS DMS </h4>
                  {/* <p>MPS System</p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/CenterLine"}
            >
              <div className="col-lg-2 col-md-6  py-2 ">
                <div
                  className="feature_item bg-white text-center"
                  // style={{
                  //   background: "linear-gradient(100deg, #5AD0F2, #2DBDFA)",
                  // }}
                  // style={bgColor}
                >
                  <img
                    src="/icons/cl.png"
                    alt="Center Line"
                    width="50"
                    height="50"
                  />
                  <h4 className="text-Card">CL DMS </h4>
                  {/* <p>Center Line System</p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link style={{ textDecoration: "none" }} to={match.url + "/Cil"}>
              <div className="col-lg-2 col-md-6  py-2 ">
                <div
                  className="feature_item bg-white text-center"
                  // style={{
                  //   background: "linear-gradient(100deg, #5AD0F2, #2DBDFA)",
                  // }}
                  // style={bgColor}
                >
                  <img src="/icons/cil.png" alt="CIL" width="50" height="50" />
                  <h4 className="text-Card">CIL DMS</h4>
                  {/* <p>Center Line System</p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/AnalyticalDashboard"}
            >
              <div className="col-lg-2 col-md-6  py-2 ">
                <div className="feature_item bg-white text-center">
                  <img
                    src="/icons/Analytics.png"
                    alt="Dashboard"
                    width="50"
                    height="50"
                  />
                  <h4 className="text-Card">Dashboard</h4>
                  {/* <p>Dashboard </p> */}
                </div>
              </div>
            </Link>
          </div>
          <div className="hvr-shadow-radial">
            <Link
              style={{ textDecoration: "none" }}
              to={match.url + "/email-config"}
            >
              <div className="col-lg-2 col-md-6  py-2 ">
                <div className="feature_item bg-white text-center">
                  <img
                    src="/icons/content-management-system.png"
                    alt="Dashboard"
                    width="50"
                    height="50"
                  />
                  <h4 className="text-Card">Email Config</h4>
                  {/* <p>Dashboard </p> */}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

// <div className="d-flex flex-wrap w-100">
// <div className=" d-flex justify-content-evenly py-2 text-center mobile-responsive-width">
//   <div
//     className="custom-card card mx-2 w-100"
//     // className=" btn-icon-text my-2 me-2 btn btn-white"
//     onClick={() => {
//       setIsActive((preValue) => {
//         return {
//           ...preValue,
//           DH: true,
//           DDS: false,
//           CL: false,
//           MPS: false,
//           CIL: false,
//         };
//       });
//     }}
//     style={
//       isActive.DH === true
//         ? { backgroundColor: "green", color: "white" }
//         : {}
//     }
//   >
//     <Link
//       style={{ textDecoration: "none" }}
//       to={match.url + "/DefectHandling"}
//     >
//       <div className="card-body">
//         <div className="card-order ">
//           <label className="main-content-label">
//             {/* <AttractionsIcon />  */}
//             <img
//               src="/icons/dh.png"
//               // className="w-25"
//               alt="DH"
//               width="75"
//               height="75"
//             />
//             <br />
//             <h5 className="py-2 fw-bold">DMS DH</h5>
//           </label>
//         </div>
//       </div>
//     </Link>
//   </div>

//   <div
//     className="custom-card card mx-2 w-100"
//     onClick={() => {
//       setIsActive((preValue) => {
//         return {
//           ...preValue,
//           DH: false,
//           CL: false,
//           MPS: false,
//           CIL: false,
//           DDS: true,
//         };
//       });
//     }}
//     style={
//       isActive.DDS === true
//         ? { backgroundColor: "green", color: "white" }
//         : {}
//     }
//   >
//     <Link
//       style={{ textDecoration: "none" }}
//       to={match.url + "/DailyDirectionSettings"}
//     >
//       <div className="card-body">
//         <div className="card-order ">
//           <label className="main-content-label">
//             {/* <ArticleIcon /> Daily Direction Setting{" "} */}
//             {/* <GroupsIcon /> */}
//             <img
//               src="/icons/dds.png"
//               // className="w-25"
//               alt="DDS"
//               width="75"
//               height="75"
//             />
//             <br />
//             <h5 className="py-2 fw-bold">DMS DDS</h5>
//           </label>
//         </div>
//       </div>
//     </Link>
//   </div>
// </div>
// <div className=" d-flex justify-content-evenly py-2 text-center mobile-responsive-width">
//   <div
//     className="custom-card card mx-2 w-100"
//     onClick={() => {
//       setIsActive((preValue) => {
//         return {
//           ...preValue,
//           DH: false,
//           CL: false,
//           MPS: true,
//           CIL: false,
//           DDS: false,
//         };
//       });
//     }}
//     style={
//       isActive.MPS === true
//         ? { backgroundColor: "green", color: "white" }
//         : {}
//     }
//   >
//     <Link style={{ textDecoration: "none" }} to={match.url + "/Mps"}>
//       <div className="card-body">
//         <div className="card-order ">
//           <label className="main-content-label">
//             {/* <AutoAwesomeMosaicIcon /> */}
//             <img
//               src="/icons/mps.png"
//               // className="w-25"
//               alt="MPS"
//               width="75"
//               height="75"
//             />
//             <br />
//             <h5 className="py-2 fw-bold">DMS MP&S</h5>
//           </label>
//         </div>
//       </div>
//     </Link>
//   </div>

//   <div
//     className="custom-card card mx-2 w-100"
//     onClick={() => {
//       setIsActive((preValue) => {
//         return {
//           ...preValue,
//           DH: false,
//           CL: true,
//           MPS: false,
//           CIL: false,
//           DDS: false,
//         };
//       });
//     }}
//     style={
//       isActive.CL === true
//         ? { backgroundColor: "green", color: "white" }
//         : {}
//     }
//   >
//     <Link
//       style={{ textDecoration: "none" }}
//       to={match.url + "/CenterLine"}
//     >
//       <div className="card-body">
//         <div className="card-order ">
//           <label className="main-content-label">
//             {/* <CableIcon fontSize="" /> */}
//             <img
//               src="/icons/cl.png"
//               alt="Center Line"
//               width="75"
//               height="75"
//             />
//             <br />
//             <h5 className="py-2 fw-bold">DMS CL</h5>
//           </label>
//         </div>
//       </div>
//     </Link>
//   </div>
// </div>
// </div>
