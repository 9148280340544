import { SELECT_QUES_MPSADMIN, userRequest } from "../../apiRoutes/apiRoutes";
import { getQuestionMPSStart,getQuestionMPSFailure,getQuestionMPSSuccess } from "../reducers/mpsQuestionReducer";
export const selectAdminQuestionMPandS = async (dispatch) => {
  dispatch(getQuestionMPSStart());
  try {
    const result = await userRequest.get(`${SELECT_QUES_MPSADMIN}`);
    // console.log("result from admin getQuestionDH==================>", result);
    dispatch(getQuestionMPSSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getQuestionMPSFailure());
  }
};
