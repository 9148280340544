import React from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { FazerAnimation } from "react-fazer-animation";

export const TSetter = (props) => {
  const history = useHistory();

  //using query-string lib to parse query string coming from landing page
  const parsed = queryString.parse(props.location.search);
  localStorage.setItem("isRefresh", "true");

  // console.log(parsed.token);
  // const parsed = {
  //   token:
  //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzcsInVzZXJuYW1lIjoiQWxwaGEiLCJlbWFpbCI6ImFscGhhQHBhLmNvbSIsImlzQWRtaW4iOiJBZG1pbiIsIm9mc1JvbGUiOiJBZG1pbiIsImJvc1JvbGUiOiJBZG1pbiIsImRlZmVjdFJvbGUiOiJBZG1pbiIsImNpbFJvbGUiOiIiLCJjbFJvbGUiOiJBZG1pbiIsIm9mc1ZhbHVlIjoyLCJkZXB0TmFtZSI6IlRlc3QiLCJpYXQiOjE2NTU3MjE4NzYsImV4cCI6MTY1NTcyNTQ3Nn0.V0ovmOhva8L0cAN_cyK81ZXA6eQcdoWiia5E1g82UsU",
  // };

  //Setting token in local Storage and Redirecting to dashboard

  //Checking Token
  if (!parsed.token) {
    console.log("in if block");
    localStorage.removeItem("isRefresh");
    window.location.href = "https://finehhdigital.com/signin";
  }
  //if token is avalaible then redirect to dashboard
  else {
    console.log("in else block");
    localStorage.setItem("token", parsed.token);
    history.push("/dashboard");
  }

  // Funtion for redirecting
  const handleSignIn = () => {
    window.location.href = "https://finehhdigital.com/signin";
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a style={{ cursor: "pointer" }} href onClick={handleSignIn}>
        <FazerAnimation
          title={"Sign In To Continue..."}
          bodyColor={"cyan"}
          longfazersColor={"linear-gradient(black,red)"}
        />
      </a>
    </div>
  );
};
