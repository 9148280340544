import {
  getAdminDDSStart,
  getAdminDDSFailure,
  getAdminDDSSuccess,
  addAdmnDDSStart,
  addAdmnDDSFailure,
  addAdmnDDSSuccess,
  updateAdminDDSStart,
  updateAdminDDSFailure,
  updateAdminDDSSuccess,
  deleteAdminDDSStart,
  deleteAdminDDSFailure,
  deleteAdminDDSSuccess
} from "../reducers/adminDDSReducer";
import { ADD_QUES_DAILY_DIRECTION,UPDATE_QUES_DAILY_DIRECTION,DELETE_QUES_DAILY_DIRECTION, SELECT_QUES_DAILY_DIRECTION, userRequest } from "../../apiRoutes/apiRoutes";
import { toast } from "react-toastify";

export const getQuestionDDS = async (dispatch) => {
  dispatch(getAdminDDSStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_DAILY_DIRECTION}`);
    console.log("response from get DDS admin ==>", res);
    dispatch(getAdminDDSSuccess(res.data.payload));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminDDSFailure());
  }
};

export const createQuestionDDS = async (dispatch, inputData) => {
  dispatch(addAdmnDDSStart());
  try {
    const result = await userRequest.post(`${ADD_QUES_DAILY_DIRECTION}`, inputData);
    // console.log("result from createQuestionDH",result);
    dispatch(addAdmnDDSSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdmnDDSFailure());
  }
};
export const updateQuestionDDS = async (userData, dispatch) => {
  console.log("userData====>",userData);
  dispatch(updateAdminDDSStart());
  try {
    const res = await userRequest.post(`${UPDATE_QUES_DAILY_DIRECTION}`, userData);
    // console.log("response from updtae defect handling question", res);
    dispatch(updateAdminDDSSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminDDSFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionDDS = async (id, dispatch) => {
  dispatch(deleteAdminDDSStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_DAILY_DIRECTION}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminDDSSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminDDSFailure());
    toast.error("Server Error");
  }
};
