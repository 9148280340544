import {
  CREATE_DEFECT_USER,
  SELECT_DEFECT_USER,
  userRequest,
} from "../../apiRoutes/apiRoutes";
import {
  getUserDHStart,
  getUserDHSuccess,
  getUserDHFailure,
  addUserDHStart,
  addUserDHSuccess,
  addUserDHFailure,
} from "../reducers/userDHReducer";
import { toast } from "react-toastify";

export const createUserDataDH = async (dispatch, inputData) => {
  dispatch(addUserDHStart());
  try {
    const result = await userRequest.post(`${CREATE_DEFECT_USER}`, inputData);
    console.log("result from createQuestionDH", result);
    // toast.success("Feedback Added Successfully");

    dispatch(addUserDHSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");

    dispatch(addUserDHFailure());
  }
};
export const selectUserDataDH = async (dispatch) => {
  dispatch(getUserDHStart());
  try {
    const result = await userRequest.get(`${SELECT_DEFECT_USER}`);
    // console.log("result from getQuestionDH", result);
    // toast.success("Successfully")
    dispatch(getUserDHSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getUserDHFailure());
  }
};
