import React from "react";
import "../../components/Drawers/Drawer.css";
import BreadCrumbs from "../../components/BreadCrumbs/BreadCrumbs";

import AdminMpsTabsComp from "../../components/TabsComp/AdminMpsTabsComp";

export const AdminMPS = () => {
  return (
    <div>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            MP&S DMS Health Check
          </h2>
          <BreadCrumbs first="Mps" second="AdministrationMP&S" />
        </div>
      </div>
      <div>
        <AdminMpsTabsComp />
      </div>
    </div>
  );
};
