import { createSlice } from "@reduxjs/toolkit";

const AdminCLSlice = createSlice({
  name: "AdminCL",
  initialState: {
    AdminCL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCL
    getAdminCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminCLSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminCL = action.payload;
    },
    getAdminCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCL
    deleteAdminCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminCLSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCL
    updateAdminCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminCLSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCL
    addAdmnCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdmnCLSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCL.push(action.payload);
    },
    addAdmnCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminCLStart,
  getAdminCLSuccess,
  getAdminCLFailure,
  deleteAdminCLStart,
  deleteAdminCLSuccess,
  deleteAdminCLFailure,
  updateAdminCLStart,
  updateAdminCLSuccess,
  updateAdminCLFailure,
  addAdmnCLStart,
  addAdmnCLSuccess,
  addAdmnCLFailure,
} = AdminCLSlice.actions;
export default AdminCLSlice.reducer;
