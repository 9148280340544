import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AttractionsIcon from "@mui/icons-material/Attractions";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import CableIcon from "@mui/icons-material/Cable";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import "./Drawer.css";
import Finelogo from "../../images/white.png";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GroupsIcon from "@mui/icons-material/Groups";

import DashboardIcon from "@mui/icons-material/Dashboard";
import { DefectHandling } from "../../pages/Defecthandling/DefectHandling";
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import { CenterLine } from "../../pages/centerline/CenterLine";
import { DailyDirection } from "../../pages/DDS/DailyDirection";
import { Mps } from "../../pages/Mp&s/Mps";
import { Home } from "../../pages/HomePage/Home";
import { AdminDefectHandling } from "../../pages/Defecthandling/AdminDH";
import { AdminDDS } from "../../pages/DDS/AdminDDS";
import { AdminMPS } from "../../pages/Mp&s/AdminMPS";
import { AdminCL } from "../../pages/centerline/AdminCL";
import HomeIcon from "@mui/icons-material/Home";
import jwt from "jsonwebtoken";
import {
  handleLogoutRedirect,
  handlePageRedirect,
  handleReload,
} from "../../apiRoutes/windowsLocation";
// import SetCalender from "../../pages/SetCalender/SetCalender";
import AnalyticalDashboard from "../../pages/AnalyticalDashboard/AnalyticalDashboard";
import { UserCil } from "../../pages/CIL/UserCil";
import { AdminCiL } from "../../pages/CIL/AdminCil";
import EmailConfig from "../../pages/EmailConfig/EmailConfig";
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';


const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const location = useLocation();
  const match = useRouteMatch();
  const history = useHistory();

  // useEffect(() => {
  //   console.log("location.pathname >>", location.pathname);
  //   setTimeout(() => {
  //     history.push(location.pathname);
  //   }, 100);
  // }, []);
  useEffect(() => {
    const isRefresh = localStorage.getItem("isRefresh");
    if (isRefresh) {
      setTimeout(() => {
        console.log("realod >>>>");
        localStorage.removeItem("isRefresh");
        handleReload()
      }, 500);
    }
  }, []);
  const backcolor = { color: "white" };
  const display = { color: "white" };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  //userRole
  const [role, setRole] = useState();
  console.log("role ==> ", role);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const goBack = () => {
    history.goBack();
  };
  const goForward = () => {
    history.goForward();
  };
  var decoded;
  // console.log("decoded ==>", decoded);

  function decoder() {
    var token = localStorage.getItem("token");
    decoded = jwt.decode(token, { complete: true });

    if (decoded == null) {
      history.push("/");
    } else {
      // console.log("decoded data ==>",decoded.payload);
      localStorage.setItem("username", decoded.payload.username);
      var user = localStorage.getItem("username");
      return user;
    }
  }

  const logoutHandle = () => {
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    if (localStorage.getItem("username")) {
      console.log("here");
    } else {
      // window.location.href = "https://finehhdigital.com/signin";
      handleLogoutRedirect();
    }
  };
  // const HandleHomepage = () => {
  //   window.location.href = "https://finehhdigital.com/home";
  // };

  useEffect(() => {
    var token = localStorage.getItem("token");
    // console.log("token without is Admin =>", token);
    var decoded = jwt.decode(token, { complete: true });
    // console.log("token ==>", decoded);
    setRole(decoded.payload.bosRole);
    decoder();
    if (!decoded) {
      // return (<>Please Login First </>)
      // return (window.location.href = "https://finehhdigital.com/signin");
      return handleLogoutRedirect();
    }
  }, []);

  const drawer = (
    <div className="forborder">
      <Toolbar className="text-light  sidemenu-logo ">HEALTH CHECK</Toolbar>

      <List style={{ fontSize: "14px" }}>
        <div
          className="dashboard-btn m-3 mt-2"
          // onClick={HandleHomepage}
          onClick={handlePageRedirect}
        >
          <Link style={{ textDecoration: "none", color: "grey" }}>
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "https://finehhdigital.com/home"
                  ? display
                  : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
              >
                Home
              </div>
              <div>
                <Typography>
                  {" "}
                  <HomeIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={location.pathname === "/dashboard" ? display : {}}
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                All Health Checks
              </div>
              <div>
                <Typography>
                  {" "}
                  <DashboardIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/DefectHandling"
            to={match.url + "/AnalyticalDashboard"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/AnalyticalDashboard"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                Dashboard
              </div>
              <div>
                <Typography>
                  {" "}
                  <AttractionsIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/DefectHandling"
            to={match.url + "/DefectHandling"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/DefectHandling" ||
                location.pathname === "/dashboard/AdministrationDefectHandling"
                  ? backcolor
                  : {}
              }
            >
              <div
                // onClick={chBackcolor}
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                {/* Defect handling */}
                DH DMS
              </div>
              <div>
                <Typography>
                  {" "}
                  <AttractionsIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            // to="/DailyDirectionSetting"
            to={match.url + "/DailyDirectionSettings"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/DailyDirectionSettings" ||
                location.pathname === "/dashboard/AdministrationDailyDirection"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                {/* D.direction setting */}
                DDS DMS
              </div>
              <div>
                <Typography>
                  {" "}
                  <GroupsIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url + "/Mps"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/Mps" ||
                location.pathname === "/dashboard/AdministrationMps"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="javascript"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                MP&S DMS
              </div>
              <div>
                <Typography>
                  {" "}
                  <AutoAwesomeMosaicIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url + "/CenterLine"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/CenterLine" ||
                location.pathname === "/dashboard/AdministrationCenterLine"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="/CenterLine"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                {/* Center Line */}
                CL DMS
              </div>
              <div>
                <Typography>
                  {" "}
                  <CableIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url + "/Cil"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/Cil" ||
                location.pathname === "/dashboard/AdministrationCil"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="/Cil"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                CIL DMS
              </div>
              <div>
                <Typography>
                  {" "}
                  <CleanHandsIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div>

        <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url + "/email-config"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/email-config" 
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                href="/Cil"
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                Email Config
              </div>
              <div>
                <Typography>
                  {" "}
                  < SpatialTrackingIcon/> 
                </Typography>
              </div>
            </div>
          </Link>
        </div>
        {/* <div className="dashboard-btn m-3 mt-2">
          <Link
            style={{ textDecoration: "none", color: "grey" }}
            to={match.url + "/SetCalender"}
          >
            <div
              className="d-flex btn-hover flex-row-reverse text-uppercase "
              style={
                location.pathname === "/dashboard/SetCalender" ||
                location.pathname === "/dashboard/AdministrationSetCalender"
                  ? backcolor
                  : {}
              }
            >
              <div
                className="w-100 p-1 dashboard-btn3  justify-content-md-start "
                variant="text "
                sx={{ bgcolor: "#002564", color: "#D3D3D3" }}
                onClick={handleDrawerToggle}
              >
                HC Calender
              </div>
              <div>
                <Typography>
                  {" "}
                  <CableIcon />
                </Typography>
              </div>
            </div>
          </Link>
        </div> */}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} style={{ height: "100%" }}>
      <CssBaseline />
      <AppBar
        className="coloring"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "#002564",
          color: "#fff",
          zIndex: 20,
        }}
      >
        <Toolbar className="screen-lg-menu-btn-hide">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <div className="col-sm-1  navigation-view  ">
            <div className="d-flex justify-content-center onsmallscreen">
              <div>
                <Button onClick={goBack}>
                  <ArrowBackIosIcon
                    style={{ fill: "white" }}
                    sx={{ display: { xs: "none", sm: "inline" } }}
                  />
                </Button>
              </div>
              <div>
                <Button onClick={goForward}>
                  <ArrowForwardIosIcon
                    style={{ fill: "white" }}
                    sx={{ display: { xs: "none", sm: "inline" } }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <img
              src={Finelogo}
              className="logosize  "
              style={{ marginLeft: "10px" }}
              width="130px"
              alt="saime"
            />
          </Typography>
          <Typography>{decoder().toUpperCase()}</Typography>
          <a
            href="javascript:void"
            className="text-decoration-none pointer-cursor text-white ms-2"
            onClick={logoutHandle}
          >
            <LogoutIcon />
          </a>

          {/* <Button color="inherit" onClick={logoutHandle}>
            <LogoutIcon />
          </Button> */}
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, zIndex: 0 }}
        aria-label="mailbox folders"
        className="drawerbackground"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#002564",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        className="smallscreensizes w-100 "
        style={{
          backgroundColor: "#EAEDF7",
          // backgroundCover: 'cover',
        }}
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Switch>
          <Route exact path={match.url + ""}>
            <Home />
          </Route>
          <Route path={match.url + "/AnalyticalDashboard"}>
            <AnalyticalDashboard />{" "}
          </Route>
          <Route path={match.url + "/DefectHandling"}>
            <DefectHandling />
          </Route>
          <Route path={match.url + "/AdministrationDefectHandling"}>
            <AdminDefectHandling />{" "}
          </Route>
          <Route path={match.url + "/CenterLine"}>
            <CenterLine />
          </Route>
          <Route path={match.url + "/DailyDirectionSettings"}>
            <DailyDirection />
          </Route>
          <Route path={match.url + "/Mps"}>
            <Mps />
          </Route>
          <Route path={match.url + "/Cil"}>
            <UserCil />
          </Route>
          {/* <Route path={match.url + "/SetCalender"}>
            <SetCalender />
          </Route> */}
          <Route path={match.url + "/AdministrationDailyDirection"}>
            <AdminDDS />
          </Route>
          <Route path={match.url + "/AdministrationMps"}>
            <AdminMPS />
          </Route>
          <Route path={match.url + "/AdministrationCenterLine"}>
            <AdminCL />
          </Route>
          <Route path={match.url + "/AdministrationCil"}>
            <AdminCiL />
          </Route>
          <Route path={match.url + "/email-config"}>
            <EmailConfig />
          </Route>

          <Route path={match.url + "*"}>
            <Home />
          </Route>
          {/* <Home /> */}
        </Switch>
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
