import {
  getAdminMPSStart,
  getAdminMPSFailure,
  getAdminMPSSuccess,
  addAdmnMPSSuccess,
  addAdmnMPSStart,
  addAdmnMPSFailure,
  updateAdminMPSFailure,
  updateAdminMPSSuccess,
  updateAdminMPSStart,
  deleteAdminMPSFailure,
  deleteAdminMPSStart,
  deleteAdminMPSSuccess,
} from "../reducers/adminMPSReducer";
import {
  ADD_QUES_MPandS,
  UPDATE_QUES_MPandS,
  DELETE_QUES_MPandS,
  SELECT_QUES_MPandS,
  userRequest,
} from "../../apiRoutes/apiRoutes";
import { toast } from "react-toastify";

export const getQuestionMPandS = async (dispatch) => {
  dispatch(getAdminMPSStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_MPandS}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminMPSSuccess(res.data.payload));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminMPSFailure());
  }
};

export const createQuestionMPandS = async (dispatch, inputData) => {
  dispatch(addAdmnMPSStart());
  try {
    const result = await userRequest.post(`${ADD_QUES_MPandS}`, inputData);
    // console.log("result from createQuestionDH",result);
    dispatch(addAdmnMPSSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdmnMPSFailure());
  }
};
export const updateQuestionMPandS = async (userData, dispatch) => {
  dispatch(updateAdminMPSStart());
  try {
    const res = await userRequest.post(`${UPDATE_QUES_MPandS}`, userData);
    // console.log("response from updtae defect handling question", res);
    dispatch(updateAdminMPSSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminMPSFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionMPandS = async (id, dispatch) => {
  dispatch(deleteAdminMPSStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_MPandS}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminMPSSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminMPSFailure());
    toast.error("Server Error");
  }
};
