import {
    CREATE_CL_USER,
    SELECT_CL_USER,
    userRequest,
  } from "../../apiRoutes/apiRoutes";
import { addUserCLStart,addUserCLFailure,
  addUserCLSuccess,getUserCLStart,getUserCLFailure,getUserCLSuccess } from "../reducers/userCLReducer";
  import { toast } from "react-toastify";
  
  export const createUserDataCL = async (dispatch, inputData) => {
    dispatch(addUserCLStart());
    try {
      const result = await userRequest.post(`${CREATE_CL_USER}`, inputData);
      console.log("result from createQuestionCL", result);
      // toast.success("Feedback Added Successfully");
  
      dispatch(addUserCLSuccess(result));
      toast.success("Inserted Successfully");
      return result;
    } catch (err) {
      console.log("error from catch", err);
      toast.error("Server Error");
  
      dispatch(addUserCLFailure());
    }
  };
  export const selectUserDataCL = async (dispatch) => {
    dispatch(getUserCLStart());
    try {
      const result = await userRequest.get(`${SELECT_CL_USER}`);
      // console.log("result from getQuestionDH", result);
      // toast.success("Successfully")
      dispatch(getUserCLSuccess(result.data.payload));
    } catch (err) {
      console.log("error from catch", err);
      dispatch(getUserCLFailure());
    }
  };
  