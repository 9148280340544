import {
  addAdmnCLStart,
  addAdmnCLFailure,
  addAdmnCLSuccess,
  getAdminCLStart,
  getAdminCLFailure,
  getAdminCLSuccess,
  updateAdminCLStart,
  updateAdminCLFailure,
  updateAdminCLSuccess,
  deleteAdminCLStart,
  deleteAdminCLSuccess,
  deleteAdminCLFailure
} from "../reducers/adminCLReducer";
import {
  ADD_QUES_CENTERLINE,
  UPDATE_QUES_CENTERLINE,
  DELETE_QUES_CENTERLINE,
  SELECT_QUES_CENTERLINE,
  userRequest
} from "../../apiRoutes/apiRoutes";
import { toast } from "react-toastify";

export const getQuestionCL = async (dispatch) => {
  dispatch(getAdminCLStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_CENTERLINE}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminCLSuccess(res.data.payload));
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminCLFailure());
  }
};

export const createQuestionCL = async (dispatch, inputData) => {
  dispatch(addAdmnCLStart());
  try {
    const result = await userRequest.post(`${ADD_QUES_CENTERLINE}`, inputData);
    // console.log("result from createQuestionDH",result);
    dispatch(addAdmnCLSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");
    dispatch(addAdmnCLFailure());
  }
};
export const updateQuestionCL = async (userData, dispatch) => {
  dispatch(updateAdminCLStart());
  try {
    const res = await userRequest.post(`${UPDATE_QUES_CENTERLINE}`, userData);
    // console.log("response from updtae defect handling question", res);
    dispatch(updateAdminCLSuccess(res.data));
    toast.success("Updtaed Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminCLFailure());
    toast.error("Server Error");
  }
};
export const deleteQuestionCL = async (id, dispatch) => {
  dispatch(deleteAdminCLStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_CENTERLINE}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminCLSuccess(res.data));
    toast.success("Deleted Successfully");
  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminCLFailure());
    toast.error("Server Error");
  }
};
