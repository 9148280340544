import { createSlice } from "@reduxjs/toolkit";

const AdminCILSlice = createSlice({
  name: "AdminCIL",
  initialState: {
    AdminCIL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminCIL
    getAdminCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminCILSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminCIL = action.payload;
    },
    getAdminCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminCIL
    deleteAdminCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminCILSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminCIL
    updateAdminCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminCILSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminCIL
    addAdmnCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdmnCILSuccess: (state, action) => {
      state.isFetching = false;
      // state.AdminCIL.push(action.payload);
    },
    addAdmnCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminCILStart,
  getAdminCILSuccess,
  getAdminCILFailure,
  deleteAdminCILStart,
  deleteAdminCILSuccess,
  deleteAdminCILFailure,
  updateAdminCILStart,
  updateAdminCILSuccess,
  updateAdminCILFailure,
  addAdmnCILStart,
  addAdmnCILSuccess,
  addAdmnCILFailure,
} = AdminCILSlice.actions;
export default AdminCILSlice.reducer;
