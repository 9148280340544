import React from 'react'

const AnalyticalDashboard = () => {
    return (
        <div style={{ height: "100vh" }}>
            <iframe
                style={{ height: "100%", width: "100%" }}
                title="FineHC-SQL"
                src="https://app.powerbi.com/view?r=eyJrIjoiOGVjMjNmMDktZmQ4Mi00MGNiLWJlMTUtNzcyOGQzOGJlMjRiIiwidCI6IjdjNDk0OTczLTBmOWYtNDMxZC05NWI4LTA0NTdmYzJmNzI4OCIsImMiOjl9"
                frameBorder={0}
                allowFullScreen="true"
            >
            </iframe>
        </div>
    )
}

export default AnalyticalDashboard