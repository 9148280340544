import Breadcrumb from 'react-bootstrap/Breadcrumb';
import React from 'react'
function BreadCrumbs(props) {
  return (
    <Breadcrumb className='textdecorationoflink' style={{fontSize:'12px'}} >
      <Breadcrumb.Item  >Home</Breadcrumb.Item>
      <Breadcrumb.Item     >
       {props.first}
      </Breadcrumb.Item>
      {props.second ? 
            <Breadcrumb.Item active>{props.second}</Breadcrumb.Item>: ['']

    }
    </Breadcrumb>
  );
}

export default BreadCrumbs;
