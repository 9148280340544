import { SELECT_QUES_DHADMIN, userRequest } from "../../apiRoutes/apiRoutes";
import {
  getQuestionDHStart,
  getQuestionDHFailure,
  getQuestionDHSuccess,
} from "../reducers/dhQuestionReducer";
export const selectAdminQuestionDH = async (dispatch) => {
  dispatch(getQuestionDHStart());
  try {
    const result = await userRequest.get(`${SELECT_QUES_DHADMIN}`);
    // console.log("result from admin getQuestionDH==================>", result);
    dispatch(getQuestionDHSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getQuestionDHFailure());
  }
};
