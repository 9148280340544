import { createSlice } from "@reduxjs/toolkit";

const LineLeaderEmailSlice = createSlice({
    name: 'LLEmail',
    initialState: {
        LLEmail:[],
        isFetching: false,
        error: false,
    },
    reducers:{
       getLLEmailStart: (state)=> {
        state.isFetching = true;
        state.error = false;
      },
      getLLEmailSuccess: (state, action)=> {
        state.isFetching = true;
        state.LLEmail = action.payload;
      },
      getLLEmailFailure: (state)=> {
        state.isFetching = false;
        state.error = true;
      }, 
    createEmailLLStart: (state) => {
        state.isFetching = true;
        state.error = false;
      },
      createEmailLLSuccess: (state, action) => {
        console.log("action>>>", action);
        state.isFetching = false;
        state.LLEmail = action.payload;
      },
      createEmailLLFailure: (state) => {
        state.isFetching = false;
        state.error = true;
      },
      updateEmailLLStart: (state) => {
        state.isFetching = true;
        state.error = false;
      },
      updateEmailLLSuccess: (state, action) => {
        console.log("action",action);
        state.isFetching = false;
        state.LLEmail = action.payload;
      },
      updateEmailLLFailure: (state) => {
        state.isFetching = false;
        state.error = true;
      },
      deleteEmailLLStart: (state) => {
        state.isFetching = true;
        state.error = false;
      },
      deleteEmailLLSuccess: (state, action) => {
        state.isFetching = false;
        state.LLEmail = action.payload;
      },
      deleteEmailLLFailure: (state) => {
        state.isFetching = false;
        state.error = true;
      },
    },

});
export const {
    getLLEmailStart, 
    getLLEmailSuccess, 
    getLLEmailFailure,
    createEmailLLStart,
    createEmailLLSuccess,
    createEmailLLFailure,
    updateEmailLLStart,
    updateEmailLLSuccess,
    updateEmailLLFailure,
    deleteEmailLLStart,
    deleteEmailLLSuccess,
    deleteEmailLLFailure,
} = LineLeaderEmailSlice.actions

export default LineLeaderEmailSlice.reducer;