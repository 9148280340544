import { SELECT_QUES_CLADMIN, userRequest } from "../../apiRoutes/apiRoutes";
import { getQuestionCLStart,getQuestionCLFailure,getQuestionCLSuccess } from "../reducers/clQuestionReducer";
export const selectAdminQuestionCL = async (dispatch) => {
  dispatch(getQuestionCLStart());
  try {
    const result = await userRequest.get(`${SELECT_QUES_CLADMIN}`);
    // console.log("result from admin getQuestionDH==================>", result);
    dispatch(getQuestionCLSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getQuestionCLFailure());
  }
};
