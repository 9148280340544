import {
  addAdmnDHFailure,
  addAdmnDHStart,
  addAdmnDHSuccess,
  getAdminDHFailure,
  getAdminDHStart,
  getAdminDHSuccess,
  updateAdminDHStart,
  updateAdminDHFailure,
  updateAdminDHSuccess,
  deleteAdminDHStart,
  deleteAdminDHFailure,
  deleteAdminDHSuccess,
} from "../reducers/adminDHReducer";
import {
  ADD_QUES_DEFECT_HANDLING,
  SELECT_QUES_DEFECT_HANDLING,
  UPDATE_QUES_DEFECT_HANDLING,
  DELETE_QUES_DEFECT_HANDLING,
  userRequest,
} from "../../apiRoutes/apiRoutes";
import { toast } from "react-toastify";


export const getQuestionDH = async (dispatch) => {
  dispatch(getAdminDHStart());
  try {
    const res = await userRequest.get(`${SELECT_QUES_DEFECT_HANDLING}`);
    // console.log("response from get DH admin ==>", res);
    dispatch(getAdminDHSuccess(res.data.payload));
    
  } catch (error) {
    console.log("error from UserFailure >>>", error);
    dispatch(getAdminDHFailure());
  }
};

export const createQuestionDH = async (dispatch, inputData) => {
  dispatch(addAdmnDHStart());
  try {
    const result = await userRequest.post(`${ADD_QUES_DEFECT_HANDLING}`, inputData);
    // console.log("result from createQuestionDH",result);
    dispatch(addAdmnDHSuccess(result));
    toast.success("Inserted Successfully")
    return result
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error")
    dispatch(addAdmnDHFailure);
  }
};
export const updateQuestionDH = async (userData, dispatch) => {
  dispatch(updateAdminDHStart());
  try {
    const res = await userRequest.post(`${UPDATE_QUES_DEFECT_HANDLING}`, userData);
    // console.log("response from updtae defect handling question", res);
    dispatch(updateAdminDHSuccess(res.data));
    toast.success("Updtaed Successfully")

  } catch (error) {
    console.log("error from catch block", error);
    dispatch(updateAdminDHFailure());
    toast.error("Server Error")

  }
};
export const deleteQuestionDH = async (id, dispatch) => {
  dispatch(deleteAdminDHStart());
  try {
    const res = await userRequest.post(`${DELETE_QUES_DEFECT_HANDLING}`, { id });
    // console.log("response from dleete admin question", res);
    dispatch(deleteAdminDHSuccess(res.data));
    toast.success("Deleted Successfully")

  } catch (error) {
    console.log("error from catch block", error);
    dispatch(deleteAdminDHFailure());
    toast.error("Server Error")

  }
};
