import axios from "axios";


// export const domain = "http://localhost:5000/";
// export const domain = "https://healthb.finehhdigital.com/";
// export const domain = "https://health-b.finehhdigital.com/";
export const domain = "https://backup-hc-b.finehhdigital.com/";
const token = localStorage.getItem("token");
export const userRequest = axios.create({
  baseURL: domain,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

//for defect handling
export const ADD_QUES_DEFECT_HANDLING =
  domain + "defectHandling/createForDefect";
export const UPDATE_QUES_DEFECT_HANDLING =
  domain + "defectHandling/updateForDefect";
export const DELETE_QUES_DEFECT_HANDLING =
  domain + "defectHandling/deleteForDefect";
export const SELECT_QUES_DEFECT_HANDLING =
  domain + "defectHandling/selectForDefect";

//DEFECT HANDLING FOR USER
export const CREATE_DEFECT_USER = domain + "defectHandling/createDefectUser";
export const SELECT_DEFECT_USER = domain + "defectHandling/selectDefectUser";

//for centerLine
export const ADD_QUES_CENTERLINE = domain + "centerLine/createForCL";
export const UPDATE_QUES_CENTERLINE = domain + "centerLine/updateForCL";
export const DELETE_QUES_CENTERLINE = domain + "centerLine/deleteForCL";
export const SELECT_QUES_CENTERLINE = domain + "centerLine/selectForCL";

//CL for USER
export const CREATE_CL_USER = domain + "centerLine/createCLUser";
export const SELECT_CL_USER = domain + "centerLine/selectCLUser";

// new work for CIL
//for CIL
export const ADD_QUES_CIL = domain + "cil/createForCIL";
export const UPDATE_QUES_CIL = domain + "cil/updateForCIL";
export const DELETE_QUES_CIL = domain + "cil/deleteForCIL";
export const SELECT_QUES_CIL = domain + "cil/selectForCIL";

//CIL for USER
export const CREATE_CIL_USER = domain + "cil/createCILUser";
export const SELECT_CIL_USER = domain + "cil/selectCILUser";

//for dds
export const ADD_QUES_DAILY_DIRECTION = domain + "DDS/createForDDS";
export const UPDATE_QUES_DAILY_DIRECTION = domain + "DDS/updateForDDS";
export const DELETE_QUES_DAILY_DIRECTION = domain + "DDS/deleteForDDS";
export const SELECT_QUES_DAILY_DIRECTION = domain + "DDS/selectForDDS";

//for DDS
export const CREATE_DDS_USER = domain + "DDS/createDDSUser";
export const SELECT_DDS_USER = domain + "DDS/selectDDSUser";
//for MPandS
export const ADD_QUES_MPandS = domain + "MPandS/createForMPandS";
export const UPDATE_QUES_MPandS = domain + "MPandS/updateForMPandS";
export const DELETE_QUES_MPandS = domain + "MPandS/deleteForMPandS";
export const SELECT_QUES_MPandS = domain + "MPandS/selectForMPandS";

//for DDS
export const CREATE_MPandS_USER = domain + "MPandS/createMPandSUser";
export const SELECT_MPandS_USER = domain + "MPandS/selectMPandSUser";

//select all question for Admin
export const SELECT_QUES_DHADMIN = domain + "Question/getDHQuestion";
export const SELECT_QUES_CLADMIN = domain + "Question/getCLQuestion";
export const SELECT_QUES_DDSADMIN = domain + "Question/getDDSQuestion";
export const SELECT_QUES_MPSADMIN = domain + "Question/getMP&SQuestion";
export const SELECT_QUES_CILADMIN = domain + "Question/getMP&SQuestion";

// Calender APIS
export const SELECT_CALENDER = domain + "calender/selectCalender";
export const CREATE_CALENDER = domain + "calender/createCalender";
export const UPDATE_CALENDER = domain + "calender/updateCalender";
export const DELETE_CALENDER = domain + "calender/deleteCalender";

// Home APIS
export const SELECT_HOME_DH = domain + "home/selectWeeklyDH";
export const SELECT_HOME_CL = domain + "home/selectWeeklyCL";
export const SELECT_HOME_DDS = domain + "home/selectWeeklyDDS";
export const SELECT_HOME_MPS = domain + "home/selectWeeklyMPS";

// LineLeaderEmail APIS
export const CREATE_LINE_LEADER_EMAIL =
  domain + "lineLeaderEmail/createLineLeaderEmail";
export const UPDATE_LINE_LEADER_EMAIL =
  domain + "lineLeaderEmail/updateLineLeaderEmail";
export const DELETE_LINE_LEADER_EMAIL =
  domain + "lineLeaderEmail/deleteLineLeaderEmail";
export const SELECT_LINE_LEADER_EMAIL =
  domain + "lineLeaderEmail/selectLineLeaderEmail";
