import { createSlice } from "@reduxjs/toolkit";

const userCILSlice = createSlice({
  name: "userCIL",
  initialState: {
    userCIL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET UserCIL
    getUserCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserCILSuccess: (state, action) => {
      state.isFetching = false;
      state.userCIL = action.payload;
    },
    getUserCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserCIL
    deleteUserCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserCILSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserCIL
    updateUserCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserCILSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserCIL
    addUserCILStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addUserCILSuccess: (state, action) => {
      state.isFetching = false;
      // state.userCIL.push(action.payload);
    },
    addUserCILFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserCILStart,
  getUserCILSuccess,
  getUserCILFailure,
  deleteUserCILStart,
  deleteUserCILSuccess,
  deleteUserCILFailure,
  updateUserCILStart,
  updateUserCILSuccess,
  updateUserCILFailure,
  addUserCILStart,
  addUserCILSuccess,
  addUserCILFailure,
} = userCILSlice.actions;
export default userCILSlice.reducer;
