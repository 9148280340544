import { createSlice } from "@reduxjs/toolkit";

const AdminQuestionDDSSlice = createSlice({
  name: "AdminQuestionDDS",
  initialState: {
    AdminQuestionDDS: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET QuestionDDS
    getQuestionDDSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getQuestionDDSSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminQuestionDDS = action.payload;
    },
    getQuestionDDSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE QuestionDDS
    
  },
});

export const {
  getQuestionDDSStart,
  getQuestionDDSSuccess,
  getQuestionDDSFailure,
 
} = AdminQuestionDDSSlice.actions;
export default AdminQuestionDDSSlice.reducer;
