import { CREATE_DDS_USER, SELECT_DDS_USER, userRequest } from "../../apiRoutes/apiRoutes";
import {
  addUserDDSStart,
  addUserDDSFailure,
  addUserDDSSuccess,
  getUserDDSStart,
  getUserDDSFailure,
  getUserDDSSuccess
} from "../reducers/userDDSReducer";
import { toast } from "react-toastify";

export const createUserDataDDS = async (dispatch, inputData) => {
  dispatch(addUserDDSStart());
  try {
    const result = await userRequest.post(`${CREATE_DDS_USER}`, inputData);
    console.log("result from createQuestionDH", result);
    // toast.success("Feedback Added Successfully");

    dispatch(addUserDDSSuccess(result));
    toast.success("Inserted Successfully");
    return result;
  } catch (err) {
    console.log("error from catch", err);
    toast.error("Server Error");

    dispatch(addUserDDSFailure());
  }
};
export const selectUserDataDDS = async (dispatch) => {
  dispatch(getUserDDSStart());
  try {
    const result = await userRequest.get(`${SELECT_DDS_USER}`);
    console.log("result from DDS user", result);
    // toast.success("Successfully")
    dispatch(getUserDDSSuccess(result.data.payload));
  } catch (err) {
    console.log("error from catch", err);
    dispatch(getUserDDSFailure());
  }
};
