
import { createSlice } from "@reduxjs/toolkit";

const globalStateSlice = createSlice({
  name: "globalState",
  initialState: {
    isFetching: false,
    error: false,
    getOwnersData: [],
    getAreaData: [],
  },

  reducers: {
    
    getOwnersData: (state, action) => {
      state.isFetching = false;
      state.getOwnersData = action.payload;
    },
    getLineOrAreaData: (state, action) => {
        console.log("acytion>>>", action);
      state.isFetching = false;
      state.getAreaData = action.payload;
    },
  },
});

export const {
  getOwnersData,
  getLineOrAreaData,
} = globalStateSlice.actions;
export default globalStateSlice.reducer;
