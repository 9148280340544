import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import MaterialTable from "material-table";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import { Col, Row } from "react-bootstrap";
import {
  createQuestionDDS,
  getQuestionDDS,
  updateQuestionDDS,
  deleteQuestionDDS,
} from "../../store/redux-apis/adminDDSApiCall";
import { useDispatch, useSelector } from "react-redux";
import { sanitizeHtml } from "../../domUtils/domPurify";

export const AdminDDSDetailTable = (props) => {
  const headerStyleObj = {
    backgroundColor: "#ffffff",
    color: "#112564",
    borderBottom: "#E4E6EF",
    borderBottomWidth: "1px",
    borderBottomStyle: "dashed",
    fontWeight: "600",
    fontSize: "12px",
  };

  const searchFieldStyleObj = {
    backgroundColor: "#EAEDF7",
    borderBottom: "0px",
    borderRadius: "10px",
    padding: "4px",
  };
  const columns = [
    {
      title: "Activity",
      field: "flowChartDesc",
      cellStyle: {
        width: "20%",
        maxWidth: "20%",
        fontSize: "12px",
      },
      headerStyle: {
        width: "20%",
        maxWidth: "20%",
        fontSize: "12px",
      },
    },

    {
      title: "Key Audit Points",
      field: "healthAuditQues",
      cellStyle: {
        width: "60%",
        maxWidth: "60%",
        fontSize: "12px",
      },
      headerStyle: {
        width: "60%",
        maxWidth: "60%",
        fontSize: "12px",
      },
    },
  ];
  let adminForDDS = useSelector((state) => state.adminDDS.adminDDS);
  if (adminForDDS.length > 0) {
    var tableDataForAdminCL = adminForDDS.map((o) => ({ ...o }));
  }

  const [show, setShow] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [question, setQuestion] = useState("");
  const [activity, setActivity] = useState("");
  const [isDisabled, setIsDisabled] = useState();

  const addData = (event) => {
    event.preventDefault();
  };
  let options = [
    {
      name: "Daily Line Meeting",
      value: "Daily Line Meeting",
    },
    {
      name: "Daily Department/Module Meeting",
      value: "Daily Department/Module Meeting",
    },
  ];
  const inputData = {
    questions: question,
    // ya flowchart activity ha DDS system ki.
    flowChartDesc: activity,
  };

  // Sanitize Inputs from xss attacks
  const sanitizedHtmlques = sanitizeHtml(question);

  const handleAddQuestion = async (e) => {
    setIsDisabled(true);

    let res = await createQuestionDDS(dispatch, inputData);
    // console.log("response from api ====>", res);
    if (res.data.success) {
      setIsDisabled(false);
      setIsRefresh((preValue) => !preValue);
      setQuestion("");
      setActivity("");
    }
  };
  const dispatch = useDispatch();
  useEffect(() => {}, []);
  useEffect(() => {
    // getQuestionCL(dispatch);
    getQuestionDDS(dispatch);
  }, [dispatch, isRefresh]);

  const handleActivity = (e) => {
    setActivity(e.target.value);
  };

  return (
    <div>
      <div>
        <div className=" material-table-parent  w-100">
          <div className="d-flex flex-md-row-reverse pb-2">
            <button
              type="button"
              className="btn-icon-text my-2 me-2 btn btn-white bg-success text-white"
              onClick={handleShow}
            >
              <AddToPhotosOutlinedIcon
                style={{ fontSize: "16px" }}
                className="me-2"
              />
              Add Key Point
            </button>
          </div>
          <MaterialTable
            style={{
              zIndex: 0,
            }}
            columns={columns}
            data={tableDataForAdminCL}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...tableDataForAdminCL];
                    const index = oldData.id;
                    dataUpdate[index] = newData;
                    updateQuestionDDS(dataUpdate[index], dispatch);
                    // getOrder(dispatch);
                    getQuestionDDS(dispatch);
                    setIsRefresh((preValue) => !preValue);

                    setShow(false);
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const index = oldData.id;
                    deleteQuestionDDS(index, dispatch);
                    setShow(false);
                    getQuestionDDS(dispatch);
                    setIsRefresh((preValue) => !preValue);
                    resolve();
                  }, 1000);
                }),
            }}
            options={{
              // actionsColumnIndex: -1,
              exportButton: true,
              search: false,
              showTitle: false,
              toolbar: false,
              filtering: true,
              pageSizeOptions: [
                5,
                10,
                20,
                {
                  value: tableDataForAdminCL ? tableDataForAdminCL.length : [],
                  label: "All",
                },
              ],
              headerStyle: headerStyleObj,
              searchFieldStyle: searchFieldStyleObj,
            }}
          />{" "}
        </div>
      </div>

      <Modal
        style={{ zIndex: "100px" }}
        className="dropdown-toggle-split w-100 "
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#002564" }}>
            New Question Details:
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addData}>
            <Form.Label>
              <h5 style={{ color: "#002564" }} p={2} className="text-lg-start">
                Activity<sup>*</sup>
              </h5>
            </Form.Label>

            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Col sm="10" className="w-100">
                <Form.Select
                  aria-label="Default select example"
                  value={activity}
                  onChange={(e) => handleActivity(e)}
                >
                  <option className="">Select Your Activity:</option>
                  {options.map((item) => {
                    return <option value={item.value}>{item.name}</option>;
                  })}
                </Form.Select>
              </Col>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <h5
                  style={{ color: "#002564" }}
                  p={2}
                  className="text-lg-start"
                >
                  Key Audit Points<sup>*</sup>
                </h5>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="New Question"
                onChange={(e) => setQuestion(e.target.value)}
                value={sanitizedHtmlques}
              />
            </Form.Group>
            <div className="d-flex justify-content-between ">
              <Button
                className="w-25 mt-2 "
                onClick={handleClose}
                style={{ backgroundColor: "grey", border: "none" }}
              >
                Close
              </Button>

              {isDisabled ? (
                <Button
                  className="w-25 mt-2"
                  disabled={isDisabled}
                  type="submit"
                  onClick={handleAddQuestion}
                  style={{ backgroundColor: "#002564", border: "none" }}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  className="w-25 mt-2 "
                  type="submit"
                  onClick={handleAddQuestion}
                  style={{ backgroundColor: "#002564", border: "none" }}
                >
                  Submit
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
