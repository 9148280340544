import { configureStore } from "@reduxjs/toolkit";
import adminDHReducer from "./reducers/adminDHReducer";
import dhQuestionReducer from "./reducers/dhQuestionReducer";
import userCLReducer from "./reducers/userCLReducer";
import adminCLReducer from "./reducers/adminCLReducer";
import userDHReducer from "./reducers/userDHReducer";
import clQuestionReducer from "./reducers/clQuestionReducer";
import adminDDSReducer from "./reducers/adminDDSReducer";
import userDDSReducer from "./reducers/userDDSReducer";
import mpsQuestionReducer from "./reducers/mpsQuestionReducer";
import ddsQuestionReducer from "./reducers/ddsQuestionReducer";
import adminMPSReducer from "./reducers/adminMPSReducer";
import userMPSReducer from "./reducers/userMPSReducer";
import calenderReducer from "./reducers/calenderReducer";
import HomeReducer from "./reducers/HomeReducer";
// NEw Work
import userCILReducer from "./reducers/userCILReducer";
import adminCILReducer from "./reducers/adminCILReducer";
import cilQuestionReducer from "./reducers/cilQuestionReducer";
import GlobalReducer from "./reducers/GlobalReducer";
import EmailReducer from "./reducers/EmailReducer";

export default configureStore({
  reducer: {
    adminDH: adminDHReducer,
    userDH: userDHReducer,
    AdminQuestionDH: dhQuestionReducer,

    userCL: userCLReducer,
    AdminCL: adminCLReducer,
    AdminQuestionCL: clQuestionReducer,

    AdminQuestionDDS: ddsQuestionReducer,
    adminDDS: adminDDSReducer,
    userDDS: userDDSReducer,

    userMPS: userMPSReducer,
    adminMPS: adminMPSReducer,
    AdminQuestionMPS: mpsQuestionReducer,

    calender: calenderReducer,
    home: HomeReducer,
    // new Work
    userCIL: userCILReducer,
    AdminCIL: adminCILReducer,
    AdminQuestionCIL: cilQuestionReducer,
    globalState:GlobalReducer,
    EmailLL: EmailReducer
  },
});
