import React, { useEffect } from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import Drawer from "./components/Drawers/Drawer";
import { TSetter } from "./components/TSetter/TSetter.js";

// // toastify;
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    // console.log(
    //   "Initially " + (window.navigator.onLine ? "on" : "off") + "line"
    // );
    window.addEventListener(
      "online",
      () => toast.success("You Are Online Now")
      // console.log("You Are Online Now")
    );
    window.addEventListener(
      "offline",
      () => toast.error("Please Check Your Internet")
      // console.log("Please Check Your Internet")
    );
  });

  return (
    <>
      <Switch>
        <Route exact path="/" component={TSetter}></Route>
        <Route path="/dashboard" component={Drawer}></Route>
      </Switch>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
