import { createSlice } from "@reduxjs/toolkit";

const userMPSSlice = createSlice({
  name: "userMPS",
  initialState: {
    userMPS: [],
    isFetching: false,
    isSuccess: false,
    error: false,
  },
  reducers: {
    //GET UserMPS
    getUserMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getUserMPSSuccess: (state, action) => {
      state.isFetching = false;
      state.userMPS = action.payload;
    },
    getUserMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE UserMPS
    deleteUserMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteUserMPSSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteUserMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE UserMPS
    updateUserMPSStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateUserMPSSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateUserMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD UserMPS
    addUserMPSStart: (state) => {
      state.isFetching = true;
      state.isSuccess = true;
      state.error = false;
    },
    addUserMPSSuccess: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      // state.userMPS.push(action.payload);
    },
    addUserMPSFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getUserMPSStart,
  getUserMPSSuccess,
  getUserMPSFailure,
  deleteUserMPSStart,
  deleteUserMPSSuccess,
  deleteUserMPSFailure,
  updateUserMPSStart,
  updateUserMPSSuccess,
  updateUserMPSFailure,
  addUserMPSStart,
  addUserMPSSuccess,
  addUserMPSFailure,
} = userMPSSlice.actions;
export default userMPSSlice.reducer;
