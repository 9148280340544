import { createSlice } from "@reduxjs/toolkit";

const adminDHSlice = createSlice({
  name: "adminDH",
  initialState: {
    adminDH: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET AdminDH
    getAdminDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminDHSuccess: (state, action) => {
      state.isFetching = false;
      state.adminDH = action.payload;
    },
    getAdminDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE AdminDH
    deleteAdminDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteAdminDHSuccess: (state, action) => {
      state.isFetching = false;
    },
    deleteAdminDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //UPDATE AdminDH
    updateAdminDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateAdminDHSuccess: (state, action) => {
      state.isFetching = false;
    },
    updateAdminDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //ADD AdminDH
    addAdmnDHStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdmnDHSuccess: (state, action) => {
      state.isFetching = false;
      // state.adminDH.push(action.payload);
    },
    addAdmnDHFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getAdminDHStart,
  getAdminDHSuccess,
  getAdminDHFailure,
  deleteAdminDHStart,
  deleteAdminDHSuccess,
  deleteAdminDHFailure,
  updateAdminDHStart,
  updateAdminDHSuccess,
  updateAdminDHFailure,
  addAdmnDHStart,
  addAdmnDHSuccess,
  addAdmnDHFailure,
} = adminDHSlice.actions;
export default adminDHSlice.reducer;
