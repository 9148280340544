import { createSlice } from "@reduxjs/toolkit";

const AdminQuestionCLSlice = createSlice({
  name: "AdminQuestionCL",
  initialState: {
    AdminQuestionCL: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //GET QuestionCL
    getQuestionCLStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getQuestionCLSuccess: (state, action) => {
      state.isFetching = false;
      state.AdminQuestionCL = action.payload;
    },
    getQuestionCLFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    //DELETE QuestionCL
    
  },
});

export const {
  getQuestionCLStart,
  getQuestionCLSuccess,
  getQuestionCLFailure,
 
} = AdminQuestionCLSlice.actions;
export default AdminQuestionCLSlice.reducer;
